import $ from "jquery";
import React, { useEffect, useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";

const ChartRanges = ({ show, options, action, rangeOverride }) => {
	const [open, setOpen] = useState(show);
	const [yValue, setYValue] = useState({});
	const [y2Value, setY2Value] = useState({});
	const [init, setInit] = useState(false);

	useEffect(() => {
		if (options.axisY) {
			setYValue({ min: rangeOverride?.axisYMinimum || options.axisY.minimum, max: rangeOverride?.axisYMaximum || options.axisY.maximum });
		}
		if (options.axisY2) {
			setY2Value({ min: rangeOverride?.axisY2Minimum || options.axisY2.minimum, max: rangeOverride?.axisY2Maximum || options.axisY2.maximum });
		}
		setInit(true);
		setOpen(show);
	}, [show, options.axisY, options.axisY2, rangeOverride]);

	const setColours = () => {
		if (options.axisY) {
			$("#input-range-y .input-range__track--active").css("background", options.axisY.titleFontColor);
			$("#input-range-y .input-range__track--active").css("border-color", options.axisY.titleFontColor);
			$("#input-range-y .input-range__slider").css("background", options.axisY.titleFontColor);
			$("#input-range-y .input-range__slider").css("border-color", options.axisY.titleFontColor);
		}
		if (options.axisY2) {
			$("#input-range-y2 .input-range__track--active").css("background", options.axisY2.titleFontColor);
			$("#input-range-y2 .input-range__track--active").css("border-color", options.axisY2.titleFontColor);
			$("#input-range-y2 .input-range__slider").css("background", options.axisY2.titleFontColor);
			$("#input-range-y2 .input-range__slider").css("border-color", options.axisY2.titleFontColor);
		}
	};

	function apply() {
		logEvent("Zone Charts", "Custom Ranges Apply");
		action("ApplyRanges", yValue, y2Value);
	}

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static">
			<ModalHeader>
				<span className="float-left">{local.TF_YAxis_Settings}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => action("CloseRanges")}>
					X
				</span>
			</ModalHeader>
			<ModalBody>
				{init && options.axisY && (
					<>
						<div className="w-100 font-weight-bold">
							{local.TS_Left} {local.TS_Axis}
						</div>
						<div id="input-range-y">
							<InputRange minValue={options.axisY.minimumSelectable} maxValue={options.axisY.maximumSelectable} value={yValue} onChange={(value) => setYValue(value)} />
						</div>
					</>
				)}
				{init && options.axisY2 && (
					<>
						<div className="w-100 font-weight-bold">
							{local.TS_Right} {local.TS_Axis}
						</div>
						<div id="input-range-y2">
							<InputRange minValue={options.axisY2.minimumSelectable} maxValue={options.axisY2.maximumSelectable} value={y2Value} onChange={(value) => setY2Value(value)} ref={setColours} />
						</div>
					</>
				)}
			</ModalBody>
			<ModalFooter>
				<Button onClick={() => action("ClearRanges")}>{local.TS_Reset}</Button>
				<Button color="primary" onClick={() => apply()}>
					{local.TS_Apply}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ChartRanges;
