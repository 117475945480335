import React from "react";
import { CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import local from "../../localization/strings";
import { roles } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFeatureEnabled, features } from "../../config";

const ProfileDetailsDisplay = (props) => {
	const userType = () => {
		switch (props.userType) {
			case roles.SiteAdmin:
				return local.TS_Site_Administrator;
			case roles.DepartmentAdmin:
				return local.TS_Department_Administrator;
			case roles.SecondaryUser:
				return local.TS_Secondary_User;
			case roles.DormantUser:
				return local.TS_Dormant_User;
			default:
				return props.userType;
		}
	};

	return (
		<>
			<CardHeader>
				<Row className="align-items-center">
					<Col className="col-6">
						<h5 className="mb-0">{local.TF_User_Details}</h5>
					</Col>
					<Col className="col-6">
						<Button onClick={props.triggerChangePassword} className="btn btn-falcon-default btn-sm float-right mt-2">
							<FontAwesomeIcon icon="key" /> {local.TF_Change_Password}
						</Button>
						{isFeatureEnabled(features.EditUserProfile) && (
							<Button onClick={props.triggerProfileEdit} className="btn btn-falcon-default btn-sm float-right mt-2 mr-lg-3">
								<FontAwesomeIcon icon="pencil-alt" /> {local.TF_Update_details}
							</Button>
						)}
					</Col>
				</Row>
			</CardHeader>
			<CardBody className="bg-light border-top">
				<Row>
					<Col className="col-12 col-lg-6 col-xxl-5">
						<h6 className="font-weight-semi-bold ls mb-3 text-uppercase">{local.TF_Account_Information}</h6>
						<Row>
							<Col className="col-5 col-sm-4">
								<p className="font-weight-semi-bold mb-1">{local.TS_Name}</p>
							</Col>
							<Col className="col">{props.name}</Col>
						</Row>
					</Col>
					<Col className="col-12 col-lg-6 col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
						<h6 className="font-weight-semi-bold ls mb-3 text-uppercase">{local.TF_Account_Details}</h6>
						<Row>
							<Col className="col-5 col-sm-4">
								<p className="font-weight-semi-bold mb-1">{local.TF_User_Type}</p>
							</Col>
							<Col>{userType()}</Col>
						</Row>
					</Col>
				</Row>
			</CardBody>
		</>
	);
};

export default ProfileDetailsDisplay;
