import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { tableIcons } from "../../helpers/tableIcons";
import local from "../../localization/strings";
import Loader from "../common/Loader";

const ChartSummary = ({ show, data, action }) => {
	const [open, setOpen] = useState(false);
	const [raw, setRaw] = useState({});

	useEffect(() => {
		setRaw(data);
		setOpen(show);
	}, [show, data]);

	return (
		<Modal isOpen={open} toggle={() => setOpen(false)} centered backdrop="static" size="xl">
			<ModalHeader>
				<span className="float-left">{local.TF_Summary}</span>
				<span className="float-right close-modal" style={{ position: "absolute", top: 15, right: 20 }} onClick={() => action("HideSummary")}>
					X
				</span>
			</ModalHeader>
			<ModalBody>{raw?.loading ? <Loader /> : <MaterialTable columns={raw.columns} data={raw.rows} title={raw.title} icons={tableIcons} options={{ sorting: true, exportButton: true, exportAllData: true, exportFileName: raw.exportTitle, paging: true, pageSize: 50, pageSizeOptions: [50], headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />}</ModalBody>
		</Modal>
	);
};

export default ChartSummary;
