import React, { useState, useEffect } from "react";
import local from "../../localization/strings";
import { apiUserFeatureDetails, apiUserFeatureSetIsEnabled, usersGetList } from "../../api/users";
import { RoleToDisplayRole } from "../../helpers/utils";
import { isFeatureEnabled, features, roles, productTypes } from "../../config";
import MaterialTable from "material-table";
import { tableIcons } from "../../helpers/tableIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import AddButton from "../common/AddButton";
import { CurrentUserHasRole } from "../../api/auth";
import confirm from "reactstrap-confirm";
import { CurrentSiteIdGet, CurrentSiteProductType } from "../../api/userSettings";
import { useCallback } from "react";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

const editIcon = () => <FontAwesomeIcon size="xs" icon="pencil-alt" />;

const UsersList = ({ editUser, addingUser }) => {
	const [users, setUser] = useState([]);
	const [featureResult, setFeatureResult] = useState();
	const departmentsFeatureEnabled = isFeatureEnabled(features.Departments);
	const [toggle, setToggle] = useState();

	const isMaintainUsersAllowed = useCallback(
		(userId) => {
			if (Object.keys(featureResult.userEnabled).includes(userId)) {
				return !featureResult.userEnabled[userId];
			}
			return !featureResult.siteEnabled;
		},
		[featureResult],
	);

	const UserEdit = ({ rowData }) => {
		if (!featureResult) {
			return null;
		}

		switch (rowData.role) {
			case roles.SiteAdmin:
				const allowed = isMaintainUsersAllowed(rowData.id);
				return <FontAwesomeIcon className="text-secondary cursor-pointer" icon={allowed ? faToggleOn : faToggleOff} size="2x" onClick={() => setToggle(rowData.id)} title={allowed ? local.TS_Yes : local.TS_No} />;
			case roles.DepartmentAdmin:
			case roles.SecondaryUser:
			case roles.DormantUser:
			default:
				return null;
		}
	};

	const columns = [
		{ field: "displayName", title: local.TS_Name, defaultSort: "asc" }, //
		{ field: "role", title: local.TS_Type, render: (rowData) => <span>{RoleToDisplayRole(rowData.role)}</span> },
		{ field: "departmentName", title: local.TS_Department, hidden: !departmentsFeatureEnabled },
		{ field: "email", title: local.TS_Email },
		{
			hidden: !(CurrentUserHasRole(roles.Eltek) || CurrentUserHasRole(roles.Distributor)),
			field: "role",
			title: local.TF_Can_Maintain_Users,
			render: (rowData) => <UserEdit rowData={rowData} />,
		},
	];

	const loadData = useCallback(async () => {
		var result = await usersGetList();

		if (CurrentUserHasRole(roles.Eltek) || CurrentUserHasRole(roles.Distributor)) {
			setFeatureResult(await apiUserFeatureDetails(CurrentSiteIdGet(), features.UserMaintenanceDisableForSiteAdmins));
		}

		result = result.filter((x) => x.role !== roles.Eltek && x.role !== roles.Distributor);

		setUser(result);
	}, []);

	const addUserHandle = async () => {
		if (users.length >= 3 && CurrentSiteProductType() === productTypes.Lite) {
			await confirm({
				title: local.TS_Warning,
				message: local.TS_Max_Lite_Users,
				cancelText: null,
				confirmText: local.TS_Close,
			});
			return;
		}
		addingUser();
	};

	useEffect(() => {
		loadData();
	}, [loadData]);

	const toggleMaintainUsers = useCallback(
		async (id) => {
			await apiUserFeatureSetIsEnabled(CurrentSiteIdGet(), id, features.UserMaintenanceDisableForSiteAdmins, isMaintainUsersAllowed(id));
			await loadData();
			setToggle(undefined);
		},
		[isMaintainUsersAllowed, loadData],
	);

	return (
		<>
			<Modal isOpen={!!toggle} toggle={() => setToggle(undefined)} centered backdrop="static">
				<ModalHeader>{local.TF_Toggle_Maintain_Users.replace("|X|", users.find((x) => x.id === toggle)?.displayName || "")}</ModalHeader>
				<ModalBody>
					<Button size="sm" color="primary" onClick={() => toggleMaintainUsers(toggle)} className="ml-2">
						{local.TS_Yes}
					</Button>
					<Button size="sm" color="secondary" onClick={() => setToggle(undefined)} className="ml-2">
						{local.TS_No}
					</Button>
				</ModalBody>
			</Modal>
			<Row>
				<Col className="text-left">{CurrentUserHasRole(roles.SiteAdmin) ? <h5 className="mb-2">{local.TF_All_Users}</h5> : <h5 className="mb-2">{local.TF_Users_in_dept}</h5>}</Col>
				<Col className="text-right">
					<AddButton string={local.TF_Add_User} buttonClasses={"mt-n2 mb-2"} clickFunction={() => addUserHandle()} />
				</Col>
			</Row>
			<div className="mx-n3 mb-n3">
				{/* Searchable/sortable data table with all users listed for site admins, and just users from their department listed for dept admins */}
				<MaterialTable
					columns={columns}
					data={users}
					title=""
					icons={tableIcons}
					options={{ exportButton: true, exportAllData: true, exportFileName: "Users", sorting: true, paging: true, pageSize: 50, pageSizeOptions: [50], emptyRowsWhenPaging: false, showEmptyDataSourceMessage: false, headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }}
					actions={[
						{
							icon: editIcon,
							tooltip: "Modify",
							onClick: (_event, rowData) => editUser(rowData.id),
						},
					]}
				/>
			</div>
		</>
	);
};

export default UsersList;
