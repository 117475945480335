import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { memoize, values } from "lodash";
import moment, { isMoment } from "moment";
import "moment/min/locales";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap";
import { chartDataRaw } from "../../api/chart";
import { SendEmail } from "../../api/email";
import { UserPreferencesContext } from "../../context/userPreferences";
import { FixDate } from "../../helpers/dateTimeHelper";
import useWindowSize from "../../hooks/useWindowSize";
import CanvasJSReact from "../../lib/canvasjs.react";
import local from "../../localization/strings";
import EmailModal from "../common/EmailModal";
import IntToHex from "../common/IntToHex";
import Loader from "../common/Loader";
import AlarmEdit from "./AlarmEdit";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const buttonDefaultClasses = "mr-1 btn-shadow btn-sm mb-sm-2 py-2 px-2 px-sm-3 py-sm-1";

const GroupChart = ({ selectedGroupId }) => {
	const [showLoading, setShowLoading] = useState(true);
	const [initialised, setInitialised] = useState(false);
	const { siteMap, savePreference, preferences, prefsLoaded } = useContext(UserPreferencesContext);
	const [xId, setXId] = useState();
	const [yId, setYId] = useState();
	const [period, setPeriod] = useState("Week");
	const [data, setData] = useState({});
	const [options, setOptions] = useState({});
	const [ref, setRef] = useState({});
	const tableRef = useRef(null);
	const size = useWindowSize();
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const [language, setLanguage] = useState("en");
	const [allowShowSafeAlarm, setAllowShowSafeAlarm] = useState(false);
	const [allowShowSafeThreshold, setAllowShowSafeThreshold] = useState(false);
	const [showSafeAlarm, setShowSafeAlarm] = useState(false);
	const [showSafeThreshold, setShowSafeThreshold] = useState(false);
	const [showStatistics, setShowStatistics] = useState(false);
	const [showThresholds, setShowThresholds] = useState(false);
	const [editAlarmData, setEditAlarmData] = useState(undefined);
	const [showEmail, setShowEmail] = useState(false);

	useEffect(() => {
		setLanguage(local.getLanguage());
	}, []);

	const onParameterChange = useCallback(
		async (parameter, newValue) => {
			switch (parameter) {
				case "X":
					await savePreference("Psychometric_X", siteMap.find((x) => x.locationId === parseInt(newValue))?.locationName);
					setXId(newValue);
					break;
				case "Y":
					await savePreference("Psychometric_Y", siteMap.find((x) => x.locationId === parseInt(newValue))?.locationName);
					setYId(newValue);
					break;
				case "Period":
					await savePreference("Psychometric_Period", newValue);
					setPeriod(newValue);
					break;
				case "From":
					try {
						if (isMoment(newValue)) {
							setFrom(newValue.toDate());
							await savePreference("Psychometric_From", newValue.toDate());
						}
					} catch {
						//console.error("Failed to Parse Date!");
					}
					break;
				case "To":
					try {
						if (isMoment(newValue)) {
							setTo(newValue.toDate());
							await savePreference("Psychometric_To", newValue.toDate());
						}
					} catch {
						//console.error("Failed to Parse Date!");
					}
					break;
				case "ShowSafeAlarm":
					await savePreference("Psychometric_ShowSafeAlarm", newValue);
					setShowSafeAlarm(newValue);
					break;
				case "ShowSafeThreshold":
					await savePreference("Psychometric_ShowSafeThreshold", newValue);
					setShowSafeThreshold(newValue);
					break;
				case "ShowStatistics":
					await savePreference("Psychometric_ShowStatistics", newValue);
					setShowStatistics(newValue);
					break;
				case "ShowThresholds":
					await savePreference("Psychometric_ShowThresholds", newValue);
					setShowThresholds(newValue);
					break;
				default:
					break;
			}
		},
		[siteMap, savePreference],
	);

	const lineType = (lineTypeId) => {
		switch (lineTypeId || 0) {
			case 0:
				return "dash";
			case 1:
				return "dot";
			case 2:
				return "dashDot";
			case 3:
				return "longDashDotDot";
			default:
				return null;
		}
	};

	const buildStripline = useCallback((stripLines, alarmPlot, alarmColourBlock, alarmColour, alarmLo, alarmHi, minRange, maxRange, alarmLineType, itemColour) => {
		if (alarmPlot) {
			if (alarmColourBlock) {
				stripLines.push({
					startValue: alarmLo || minRange,
					endValue: alarmHi || maxRange,
					color: `#${IntToHex(alarmColour)}`,
					opacity: 1,
					opacityOriginal: 1,
					showOnTop: false,
					thickness: 1,
				});
			} else {
				if (typeof alarmLo === "number") {
					stripLines.push({
						value: alarmLo,
						color: `#${IntToHex(itemColour)}`,
						opacity: 1,
						opacityOriginal: 1,
						showOnTop: false,
						thickness: 1,
						lineDashType: lineType(alarmLineType),
					});
				}
				if (typeof alarmHi === "number") {
					stripLines.push({
						value: alarmHi,
						color: `#${IntToHex(itemColour)}`,
						opacity: 1,
						opacityOriginal: 1,
						showOnTop: false,
						thickness: 1,
						lineDashType: lineType(alarmLineType),
					});
				}
			}
		}
	}, []);

	useEffect(() => {
		if (prefsLoaded && !initialised) {
			setPeriod(preferences.find((x) => x.preferenceKey === "Psychometric_Period")?.preferenceValue || "Week");
			const xValue = preferences.find((x) => x.preferenceKey === "Psychometric_X")?.preferenceValue || undefined;
			if (xValue) {
				setXId(siteMap.find((x) => x.groupId === parseInt(selectedGroupId) && x.locationName === xValue)?.locationId || undefined);
			}
			const yValue = preferences.find((x) => x.preferenceKey === "Psychometric_Y")?.preferenceValue || undefined;
			if (yValue) {
				setYId(siteMap.find((x) => x.groupId === parseInt(selectedGroupId) && x.locationName === yValue)?.locationId || undefined);
			}
			const fromValue = preferences.find((x) => x.preferenceKey === "Psychometric_From")?.preferenceValue || undefined;
			if (fromValue) {
				setFrom(new Date(Date.parse(fromValue)));
			}
			const toValue = preferences.find((x) => x.preferenceKey === "Psychometric_To")?.preferenceValue || undefined;
			if (toValue) {
				setTo(new Date(Date.parse(toValue)));
			}
			const showSafeValueAlarm = preferences.find((x) => x.preferenceKey === "Psychometric_ShowSafeAlarm")?.preferenceValue || undefined;
			if (showSafeValueAlarm) {
				setShowSafeAlarm(showSafeValueAlarm === "true");
			}
			const showSafeValueThreshold = preferences.find((x) => x.preferenceKey === "Psychometric_ShowSafeThreshold")?.preferenceValue || undefined;
			if (showSafeValueThreshold) {
				setShowSafeThreshold(showSafeValueThreshold === "true");
			}
			const showStatistics = preferences.find((x) => x.preferenceKey === "Psychometric_ShowStatistics")?.preferenceValue || undefined;
			if (showStatistics) {
				setShowStatistics(showStatistics === "true");
			}
			const showThresholds = preferences.find((x) => x.preferenceKey === "Psychometric_ShowThresholds")?.preferenceValue || undefined;
			if (showThresholds) {
				setShowThresholds(showThresholds === "true");
			}
			setInitialised(true);
		}
	}, [prefsLoaded, preferences, siteMap, selectedGroupId, initialised]);

	useEffect(() => {
		let isSubscribed = true;
		async function LoadData() {
			if (selectedGroupId && xId && yId && period) {
				setShowLoading(true);

				if (period === "Custom" && (!from || !to)) {
					setShowLoading(false);
					return;
				}

				const customFromDate = period === "Custom" ? FixDate(from) : undefined;
				const customToDate = period === "Custom" ? FixDate(to) : undefined;
				var result = await chartDataRaw(selectedGroupId, -1, period, 0, `${xId},${yId}`, 0, "", customFromDate, customToDate);

				if (isSubscribed) {
					setData(result.data);

					const xOptions = {
						zoomEnabled: true,
						zoomType: "xy",
						title: {
							fontSize: 16,
							fontFamily: "Poppins, Arial",
							fontColor: "#606060",
							text: result?.data?.title,
						},
						"subtitles": [
							{
								fontSize: 12,
								fontFamily: "Poppins, Arial",
								fontColor: "#606060",
								text: result.data.rows.length > 0 ? `${moment(result.data.rows[0].DateTime).format("DD MMM YYYY HH:mm")} - ${moment(result.data.rows[result.data.rows.length - 1].DateTime).format("DD MMM YYYY HH:mm")}` : null,
							},
						],
						toolTip: {
							fontFamily: "Poppins, Arial",
							contentFormatter: function(e) {
								if ((e?.entries?.length || 0) > 0) {
									return `<div style='color: black'>
                                                ${moment(e.entries[0].dataPoint.z).format("DD MMM YYYY HH:mm")}
											</div>
                                            <div style='color: ${siteMap.find((x) => x.locationId === parseInt(xId))?.parameterColourHex || "black"}'>
												${siteMap.find((x) => x.locationId === parseInt(xId))?.locationName}: ${e.entries[0].dataPoint.x?.toLocaleString(undefined, { maximumFractionDigits: result.data?.columns.find((x) => x.field === `C${xId}`)?.decimalPlaces })}
											</div>
											<div style='color: ${siteMap.find((x) => x.locationId === parseInt(yId))?.parameterColourHex || "black"}'>
												${siteMap.find((x) => x.locationId === parseInt(yId))?.locationName}: ${e.entries[0].dataPoint.y?.toLocaleString(undefined, { maximumFractionDigits: result.data?.columns.find((x) => x.field === `C${yId}`)?.decimalPlaces })}
											</div>
										</div>`;
								}
								return null;
							},
						},
						axisX: {
							title: siteMap.find((x) => x.locationId === parseInt(xId))?.locationName,
							gridColor: "#DCDCDC",
							labelFontSize: 16,
							labelFontFamily: "Poppins, Arial",
							gridThickness: 1,
							labelFontColor: "black",
							titleFontSize: 16,
							titleFontFamily: "Poppins, Arial",
							titleFontColor: siteMap.find((x) => x.locationId === parseInt(xId))?.parameterColourHex || "black",
							stripLines: [],
						},
						axisY: {
							title: siteMap.find((x) => x.locationId === parseInt(yId))?.locationName,
							gridColor: "#DCDCDC",
							labelFontSize: 16,
							labelFontFamily: "Poppins, Arial",
							gridThickness: 1,
							labelFontColor: "black",
							titleFontSize: 16,
							titleFontFamily: "Poppins, Arial",
							titleFontColor: siteMap.find((x) => x.locationId === parseInt(yId))?.parameterColourHex || "black",
							stripLines: [],
						},
						data: [
							{
								type: "scatter",
								color: "black",
								markerType: "circle",
								dataPoints: [],
								markerSize: 4,
							},
						],
					};

					const xColumn = result.data.columns.find((x) => x.field === `C${xId}`);
					const yColumn = result.data.columns.find((x) => x.field === `C${yId}`);

					//Apply overridden alarms
					const xFoundPref = preferences.find((x) => x.preferenceKey === `Psychometric_AlarmOverride_${xId}`)?.preferenceValue || undefined;
					if (xFoundPref) {
						const prefsObj = JSON.parse(xFoundPref);
						xColumn.mainAlarmLo = prefsObj?.mainAlarmLo;
						xColumn.mainAlarmHi = prefsObj?.mainAlarmHi;
						xColumn.mainAlarmPlot = true;
						xColumn.mainAlarmColour = xColumn.mainAlarmColour || "000000";
						xColumn.mainAlarmLineType = xColumn.mainAlarmLineType || 0;
						xColumn.warnAlarmLo = prefsObj?.warnAlarmLo;
						xColumn.warnAlarmHi = prefsObj?.warnAlarmHi;
						xColumn.warnAlarmPlot = true;
						xColumn.warnAlarmColour = xColumn.warnAlarmColour || "000000";
						xColumn.warnAlarmLineType = xColumn.warnAlarmLineType || 0;
					}

					const yFoundPref = preferences.find((x) => x.preferenceKey === `Psychometric_AlarmOverride_${yId}`)?.preferenceValue || undefined;
					if (yFoundPref) {
						const prefsObj = JSON.parse(yFoundPref);
						yColumn.mainAlarmLo = prefsObj?.mainAlarmLo;
						yColumn.mainAlarmHi = prefsObj?.mainAlarmHi;
						yColumn.mainAlarmPlot = true;
						yColumn.mainAlarmColour = yColumn.mainAlarmColour || "000000";
						yColumn.mainAlarmLineType = yColumn.mainAlarmLineType || 0;
						yColumn.warnAlarmLo = prefsObj?.warnAlarmLo;
						yColumn.warnAlarmHi = prefsObj?.warnAlarmHi;
						yColumn.warnAlarmPlot = true;
						yColumn.warnAlarmColour = yColumn.warnAlarmColour || "000000";
						yColumn.warnAlarmLineType = yColumn.warnAlarmLineType || 0;
					}

					if (xColumn && yColumn) {
						if (showThresholds) {
							buildStripline(xOptions.axisX.stripLines, xColumn.mainAlarmPlot, xColumn.mainAlarmColourBlock, xColumn.mainAlarmColour, xColumn.mainAlarmLo, xColumn.mainAlarmHi, xColumn.minRange, xColumn.maxRange, xColumn.mainAlarmLineType, xColumn.itemColour);
							buildStripline(xOptions.axisX.stripLines, xColumn.warnAlarmPlot, xColumn.warnAlarmColourBlock, xColumn.warnAlarmColour, xColumn.warnAlarmLo, xColumn.warnAlarmHi, xColumn.minRange, xColumn.maxRange, xColumn.warnAlarmLineType, xColumn.itemColour);
							buildStripline(xOptions.axisY.stripLines, yColumn.mainAlarmPlot, yColumn.mainAlarmColourBlock, yColumn.mainAlarmColour, yColumn.mainAlarmLo, yColumn.mainAlarmHi, yColumn.minRange, yColumn.maxRange, yColumn.mainAlarmLineType, yColumn.itemColour);
							buildStripline(xOptions.axisY.stripLines, yColumn.warnAlarmPlot, yColumn.warnAlarmColourBlock, yColumn.warnAlarmColour, yColumn.warnAlarmLo, yColumn.warnAlarmHi, yColumn.minRange, yColumn.maxRange, yColumn.warnAlarmLineType, yColumn.itemColour);
						}

						let thresholdsWarn = false;
						let thresholdsMain = false;
						if (typeof xColumn.warnAlarmLo === "number" && typeof xColumn.warnAlarmHi === "number" && typeof yColumn.warnAlarmLo === "number" && typeof yColumn.warnAlarmHi === "number") {
							thresholdsWarn = true;
						}
						if (typeof xColumn.mainAlarmLo === "number" && typeof xColumn.mainAlarmHi === "number" && typeof yColumn.mainAlarmLo === "number" && typeof yColumn.mainAlarmHi === "number") {
							thresholdsMain = true;
						}
						setAllowShowSafeAlarm(thresholdsMain);
						setAllowShowSafeThreshold(thresholdsWarn);

						let minX = xColumn.minRange;
						let maxX = xColumn.maxRange;
						let minY = yColumn.minRange;
						let maxY = yColumn.maxRange;
						result.data.rows.forEach((row) => {
							if (typeof row[`C${xId}`] === "number" && typeof row[`C${yId}`] === "number") {
								const valueX = row[`C${xId}`];
								const valueY = row[`C${yId}`];
								if (valueX < minX) {
									minX = valueX;
								}
								if (valueX > maxX) {
									maxX = valueX;
								}
								if (valueY < minY) {
									minY = valueY;
								}
								if (valueY > maxY) {
									maxY = valueY;
								}
								xOptions.data[0].dataPoints.push({ x: valueX, y: valueY, z: row["DateTime"] });
							}
						});

						xOptions.axisX.viewportMinimum = minX - (maxX - minX) * 0.02;
						xOptions.axisX.viewportMaximum = maxX + (maxX - minX) * 0.02;
						xOptions.axisY.viewportMinimum = minY - (maxY - minY) * 0.02;
						xOptions.axisY.viewportMaximum = maxY + (maxY - minY) * 0.02;

						if (thresholdsMain && showSafeAlarm) {
							xOptions.data.push({
								type: "rangeArea",
								color: "green",
								fillOpacity: 0.2,
								lineThickness: 0,
								dataPoints: [
									{ x: xColumn.mainAlarmLo, y: [yColumn.mainAlarmLo, yColumn.mainAlarmHi], markerType: "none" }, //
									{ x: xColumn.mainAlarmHi, y: [yColumn.mainAlarmLo, yColumn.mainAlarmHi], markerType: "none" },
								],
							});
						}
						if (thresholdsWarn && showSafeThreshold) {
							xOptions.data.push({
								type: "rangeArea",
								color: "green",
								fillOpacity: 0.2,
								lineThickness: 0,
								dataPoints: [
									{ x: xColumn.warnAlarmLo, y: [yColumn.warnAlarmLo, yColumn.warnAlarmHi], markerType: "none" }, //
									{ x: xColumn.warnAlarmHi, y: [yColumn.warnAlarmLo, yColumn.warnAlarmHi], markerType: "none" },
								],
							});
						}
					}
					setOptions(xOptions);
				}
			} else {
				setData(undefined);
			}
			setShowLoading(false);
		}

		LoadData();

		return () => (isSubscribed = false);
	}, [selectedGroupId, xId, yId, period, from, to, siteMap, showSafeAlarm, showSafeThreshold, showThresholds, buildStripline, preferences]);

	useEffect(() => {
		if (data && ref?.render) {
			ref.render();
		}
	}, [ref, size, data]);

	const getSiteMapValueI = useCallback(
		(id, field) => {
			try {
				const location = siteMap.find((x) => x.locationId === parseInt(id));
				if (location) {
					return location[field];
				}
			} catch {}
			return null;
		},
		[siteMap],
	);
	const getSiteMapValueM = memoize(getSiteMapValueI, (...args) => values(args).join("_"));

	const getDataValueI = useCallback(
		(useX, field) => {
			try {
				if (data?.columns) {
					const location = data.columns.find((x) => x.field === `C${useX ? xId : yId}`);
					if (location) {
						return location[field];
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getDataValueM = memoize(getDataValueI, (...args) => values(args).join("_"));

	const getStartOfDataI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`]);
					if (temp.length > 0) {
						return moment(temp[0].DateTime).format("DD MMM YYYY HH:mm");
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getStartOfDataM = memoize(getStartOfDataI, (...args) => values(args).join("_"));

	const getEndOfDataI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`]);
					if (temp.length > 0) {
						return moment(temp[temp.length - 1].DateTime).format("DD MMM YYYY HH:mm");
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getEndOfDataM = memoize(getEndOfDataI, (...args) => values(args).join("_"));

	const getValidDataI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					return data.rows.filter((x) => x[`C${useX ? xId : yId}`]).length;
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getValidDataM = memoize(getValidDataI, (...args) => values(args).join("_"));

	const getMaxDataI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const dataPoints = data.rows.filter((x) => x[`C${useX ? xId : yId}`]).map((o) => o[`C${useX ? xId : yId}`]);
					if (dataPoints.length > 0) {
						return Math.max(...dataPoints);
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getMaxDataM = memoize(getMaxDataI, (...args) => values(args).join("_"));

	const getMaxDataFirstTimeI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`] === getMaxDataM(useX));
					if (temp.length > 0) {
						return moment(temp[0].DateTime).format("DD MMM YYYY HH:mm");
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getMaxDataM],
	);
	const getMaxDataFirstTimeM = memoize(getMaxDataFirstTimeI, (...args) => values(args).join("_"));

	const getMinDataI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const dataPoints = data.rows.filter((x) => x[`C${useX ? xId : yId}`]).map((o) => o[`C${useX ? xId : yId}`]);
					if (dataPoints.length > 0) {
						return Math.min(...dataPoints);
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getMinDataM = memoize(getMinDataI, (...args) => values(args).join("_"));

	const getMinDataFirstTimeI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`] === getMinDataM(useX));
					if (temp.length > 0) {
						return moment(temp[0].DateTime).format("DD MMM YYYY HH:mm");
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getMinDataM],
	);
	const getMinDataFirstTimeM = memoize(getMinDataFirstTimeI, (...args) => values(args).join("_"));

	const getSumI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					return data.rows.reduce((a, b) => a + b[`C${useX ? xId : yId}`], 0);
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const getSumM = memoize(getSumI, (...args) => values(args).join("_"));

	const getAverageI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const sum = getSumM(useX);
					const points = getValidDataM(useX);
					if (sum && points) {
						return sum / points;
					}
				}
			} catch {}
			return null;
		},
		[data, getSumM, getValidDataM],
	);
	const getAverageM = memoize(getAverageI, (...args) => values(args).join("_"));

	const formatNumberI = useCallback(
		(value, useX) => {
			try {
				if (data?.columns) {
					const dps = data?.columns.find((x) => x.field === `C${useX ? xId : yId}`)?.decimalPlaces;
					return value?.toLocaleString(undefined, { maximumFractionDigits: dps, minimumFractionDigits: dps });
				}
			} catch {}
			return null;
		},
		[data, xId, yId],
	);
	const formatNumberM = memoize(formatNumberI, (...args) => values(args).join("_"));

	const getLoHiI = useCallback(
		(mainAlarm, useX) => {
			try {
				const idToUse = useX ? xId : yId;

				const foundPref = preferences.find((x) => x.preferenceKey === `Psychometric_AlarmOverride_${idToUse}`)?.preferenceValue || undefined;
				if (foundPref) {
					const prefsObj = JSON.parse(foundPref);
					if (mainAlarm) {
						return { lo: prefsObj?.mainAlarmLo, hi: prefsObj?.mainAlarmHi };
					}
					return { lo: prefsObj?.warnAlarmLo, hi: prefsObj?.warnAlarmHi };
				}

				const col = data?.columns.find((x) => x.field === `C${idToUse}`);

				if (mainAlarm) {
					return { lo: col?.mainAlarmLo, hi: col?.mainAlarmHi };
				}
				return { lo: col?.warnAlarmLo, hi: col?.warnAlarmHi };
			} catch {}
		},
		[data, xId, yId, preferences],
	);
	const getLoHiM = memoize(getLoHiI, (...args) => values(args).join("_"));

	const getRangeI = useCallback(
		(mainAlarm, useX) => {
			try {
				if (data?.columns) {
					const { lo, hi } = getLoHiM(mainAlarm, useX);

					if (typeof lo === "number" && typeof hi === "number") {
						return `${formatNumberM(lo, useX)} - ${formatNumberM(hi, useX)}`;
					} else if (lo) {
						return `<= ${formatNumberM(lo, useX)}`;
					} else if (hi) {
						return `>= ${formatNumberM(hi, useX)}`;
					}

					return local.TF_No_Alarm;
				}
			} catch {}
			return null;
		},
		[data, formatNumberM, getLoHiM],
	);
	const getRangeM = memoize(getRangeI, (...args) => values(args).join("_"));

	const getReadingsWithinI = useCallback(
		(mainAlarm, useX) => {
			try {
				if (data?.columns && data?.rows) {
					const { lo, hi } = getLoHiM(mainAlarm, useX);

					if (typeof lo === "number" || typeof hi === "number") {
						return data.rows.filter((x) => x[`C${useX ? xId : yId}`] && (!lo || x[`C${useX ? xId : yId}`] > lo) && (!hi || x[`C${useX ? xId : yId}`] < hi)).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsWithinM = memoize(getReadingsWithinI, (...args) => values(args).join("_"));

	const getReadingsWithinCombinedI = useCallback(
		(mainAlarm) => {
			try {
				if (data?.columns && data?.rows) {
					const { lo: xLo, hi: xHi } = getLoHiM(mainAlarm, true);
					const { lo: yLo, hi: yHi } = getLoHiM(mainAlarm, false);

					if ((typeof xLo === "number" || typeof xHi === "number") && (typeof yLo === "number" || typeof yHi === "number")) {
						return data.rows.filter((x) => x[`C${xId}`] && (!xLo || x[`C${xId}`] > xLo) && (!xHi || x[`C${xId}`] < xHi) && x[`C${xId}`] && (!yLo || x[`C${yId}`] > yLo) && (!yHi || x[`C${yId}`] < yHi)).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsWithinCombinedM = memoize(getReadingsWithinCombinedI, (...args) => values(args).join("_"));

	const getReadingsOutsideI = useCallback(
		(mainAlarm, useX) => {
			try {
				if (data?.columns && data?.rows) {
					const { lo, hi } = getLoHiM(mainAlarm, useX);

					if (typeof lo === "number" || typeof hi === "number") {
						return data.rows.filter((x) => x[`C${useX ? xId : yId}`] && ((lo && x[`C${useX ? xId : yId}`] <= lo) || (hi && x[`C${useX ? xId : yId}`] >= hi))).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsOutsideM = memoize(getReadingsOutsideI, (...args) => values(args).join("_"));

	const getReadingsOutsideCombinedI = useCallback(
		(mainAlarm) => {
			try {
				if (data?.columns && data?.rows) {
					const { lo: xLo, hi: xHi } = getLoHiM(mainAlarm, true);
					const { lo: yLo, hi: yHi } = getLoHiM(mainAlarm, false);

					if ((typeof xLo === "number" || typeof xHi === "number") && (typeof yLo === "number" || typeof yHi === "number")) {
						return data.rows.filter((x) => x[`C${xId}`] && x[`C${yId}`] && ((xLo && x[`C${xId}`] <= xLo) || (xHi && x[`C${xId}`] >= xHi) || ((yLo && x[`C${yId}`] <= yLo) || (yHi && x[`C${yId}`] >= yHi)))).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsOutsideCombinedM = memoize(getReadingsOutsideCombinedI, (...args) => values(args).join("_"));

	const getReadingsAboveI = useCallback(
		(mainAlarm, useX) => {
			try {
				if (data?.columns && data?.rows) {
					const { hi } = getLoHiM(mainAlarm, useX);

					if (typeof hi === "number") {
						return data.rows.filter((x) => x[`C${useX ? xId : yId}`] && (hi && x[`C${useX ? xId : yId}`] >= hi)).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsAboveM = memoize(getReadingsAboveI, (...args) => values(args).join("_"));

	const getReadingsBelowI = useCallback(
		(mainAlarm, useX) => {
			try {
				if (data?.columns && data?.rows) {
					const { lo } = getLoHiM(mainAlarm, useX);

					if (typeof lo === "number") {
						return data.rows.filter((x) => x[`C${useX ? xId : yId}`] && (lo && x[`C${useX ? xId : yId}`] <= lo)).length;
					}
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);
	const getReadingsBelowM = memoize(getReadingsBelowI, (...args) => values(args).join("_"));

	const percent = useCallback((a, b) => {
		try {
			if (typeof a === "number" && b) {
				return ((a * 100) / b).toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 }) + "%";
			}
		} catch {}
		return null;
	}, []);

	const getStandardDeviationI = useCallback(
		(useX) => {
			let sumOfSquares = 0;
			let validReadings = 0;
			if (data?.rows) {
				data.rows.forEach((x) => {
					const value = x[`C${useX ? xId : yId}`];
					if (typeof value === "number") {
						sumOfSquares += value ** 2;
						++validReadings;
					}
				});

				const variance = sumOfSquares / validReadings - getAverageM(useX) ** 2;

				if (variance <= 0) {
					return 0;
				}
				return Math.sqrt(variance);
			}
			return null;
		},
		[data, getAverageM, xId, yId],
	);
	const getStandardDeviationM = memoize(getStandardDeviationI, (...args) => values(args).join("_"));

	function formatDuration(duration) {
		let parts = [];

		// return nothing when the duration is falsy or not correctly parsed (P0D)
		if (!duration || duration.toISOString() === "P0D") return "00:00:00";

		if (duration.years() >= 1) {
			const years = Math.floor(duration.years());
			parts.push(years + "y");
		}

		if (duration.months() >= 1) {
			const months = Math.floor(duration.months());
			parts.push(months + "m");
		}

		if (duration.days() >= 1) {
			const days = Math.floor(duration.days());
			parts.push(days + "d");
		}

		const hours = Math.floor(duration.hours());
		const minutes = Math.floor(duration.minutes());
		const seconds = Math.floor(duration.seconds());
		parts.push(`${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);

		return parts.join(" ");
	}

	const getTimesI = useCallback(
		(mainAlarm, useX) => {
			const times = {};
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`]);
					if (temp.length === 0) {
						return null;
					}

					times.startOfData = moment(temp[0].DateTime);
					times.endOfData = moment(temp[temp.length - 1].DateTime);
					times.totalTime = times.endOfData.diff(times.startOfData);
					times.totalTimeString = formatDuration(moment.duration(times.totalTime));

					const { lo, hi } = getLoHiM(mainAlarm, useX);
					const loSet = typeof lo === "number";
					const hiSet = typeof hi === "number";

					let timeHighTotal = 0;
					let timeHighStart = undefined;
					let lastReadingHigh = false;

					let timeLowTotal = 0;
					let timeLowStart = undefined;
					let lastReadingLow = false;

					temp.forEach((reading) => {
						const value = reading[`C${useX ? xId : yId}`];

						if (hiSet && value >= hi) {
							if (lastReadingLow) {
								timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
							}
							if (!lastReadingHigh) {
								timeHighStart = moment(reading.DateTime);
							}
							lastReadingHigh = true;
							lastReadingLow = false;
						} else if (loSet && value <= lo) {
							if (lastReadingHigh) {
								timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
							}
							if (!lastReadingLow) {
								timeLowStart = moment(reading.DateTime);
							}
							lastReadingHigh = false;
							lastReadingLow = true;
						} else if (lastReadingHigh || lastReadingLow) {
							if (lastReadingHigh) {
								timeHighTotal += moment.duration(moment(reading.DateTime).diff(timeHighStart));
							}
							if (lastReadingLow) {
								timeLowTotal += moment.duration(moment(reading.DateTime).diff(timeLowStart));
							}
							timeHighStart = undefined;
							timeLowStart = undefined;

							lastReadingHigh = false;
							lastReadingLow = false;
						}
					});

					// Check again in case final reading was high/low
					if (lastReadingHigh) {
						timeHighTotal += moment.duration(times.endOfData.diff(timeHighStart));
					}
					if (lastReadingLow) {
						timeLowTotal += moment.duration(times.endOfData.diff(timeLowStart));
					}

					times.hi = hi;
					times.hiSet = hiSet;
					times.aboveTime = hiSet ? timeHighTotal : null;
					times.aboveTimeString = hiSet ? formatDuration(moment.duration(timeHighTotal)) : null;
					times.lo = lo;
					times.loSet = loSet;
					times.belowTime = loSet ? timeLowTotal : null;
					times.belowTimeString = loSet ? formatDuration(moment.duration(timeLowTotal)) : null;
					times.outsideTime = hiSet || loSet ? timeLowTotal + timeHighTotal : null;
					times.outsideTimeString = hiSet || loSet ? formatDuration(moment.duration(times.outsideTime)) : null;
					times.withinTime = hiSet || loSet ? times.totalTime - times.outsideTime : null;
					times.withinTimeString = hiSet || loSet ? formatDuration(moment.duration(times.withinTime)) : null;

					return times;
				}
			} catch {}
			return null;
		},
		[data, xId, yId, getLoHiM],
	);

	const getTimesM = memoize(getTimesI, (...args) => values(args).join("_"));

	const getTimesCombinedI = useCallback(
		(mainAlarm, useX) => {
			const times = {};
			try {
				if (data?.rows) {
					const temp = data.rows.filter((x) => x[`C${useX ? xId : yId}`]);
					if (temp.length === 0) {
						return null;
					}

					times.startOfData = moment(temp[0].DateTime);
					times.endOfData = moment(temp[temp.length - 1].DateTime);
					times.totalTime = times.endOfData.diff(times.startOfData);
					times.totalTimeString = formatDuration(moment.duration(times.totalTime));

					const { lo: xLo, hi: xHi } = getLoHiM(mainAlarm, true);
					const { lo: yLo, hi: yHi } = getLoHiM(mainAlarm, false);
					const xLoSet = typeof xLo === "number";
					const xHiSet = typeof xHi === "number";
					const yLoSet = typeof yLo === "number";
					const yHiSet = typeof yHi === "number";

					let timeOutsideTotal = 0;
					let timeOutsideStart = undefined;
					let lastReadingOutside = false;

					temp.forEach((reading) => {
						const xValue = reading[`C${xId}`];
						const yValue = reading[`C${yId}`];

						if ((xHiSet && xValue >= xHi) || (xLoSet && xValue <= xLo) || (yHiSet && yValue >= yHi) || (yLoSet && yValue <= yLo)) {
							if (!lastReadingOutside) {
								timeOutsideStart = moment(reading.DateTime);
							}
							lastReadingOutside = true;
						} else if (lastReadingOutside) {
							timeOutsideTotal += moment.duration(moment(reading.DateTime).diff(timeOutsideStart));
							lastReadingOutside = false;
						}
					});

					// Check again in case final reading was high/low
					if (lastReadingOutside) {
						timeOutsideTotal += moment.duration(times.endOfData.diff(timeOutsideStart));
					}

					times.outsideTime = xHiSet || xLoSet || yHiSet || yLoSet ? timeOutsideTotal : null;
					times.outsideTimeString = xHiSet || xLoSet || yHiSet || yLoSet ? formatDuration(moment.duration(times.outsideTime)) : null;
					times.withinTime = xHiSet || xLoSet || yHiSet || yLoSet ? times.totalTime - times.outsideTime : null;
					times.withinTimeString = xHiSet || xLoSet || yHiSet || yLoSet ? formatDuration(moment.duration(times.withinTime)) : null;

					return times;
				}
			} catch {}
			return null;
		},
		[data, getLoHiM, xId, yId],
	);

	const getTimesCombinedM = memoize(getTimesCombinedI, (...args) => values(args).join("_"));

	const getHasAlarmOverrideI = useCallback(
		(useX) => {
			try {
				if (data?.rows) {
					const idToUse = useX ? xId : yId;

					const foundPref = preferences.find((x) => x.preferenceKey === `Psychometric_AlarmOverride_${idToUse}`)?.preferenceValue || undefined;
					return !!foundPref;
				}
			} catch {}
			return false;
		},
		[data, xId, yId, preferences],
	);

	const getHasAlarmOverrideM = memoize(getHasAlarmOverrideI, (...args) => values(args).join("_"));

	const startEditAlarm = useCallback(
		(useX) => {
			const idToUse = useX ? xId : yId;

			const { lo: warnAlarmLo, hi: warnAlarmHi } = getLoHiM(false, useX);
			const { lo: mainAlarmLo, hi: mainAlarmHi } = getLoHiM(true, useX);

			setEditAlarmData({
				id: idToUse, //
				buildingName: getSiteMapValueM(idToUse, "buildingName"),
				zoneName: getSiteMapValueM(idToUse, "zoneName"),
				groupName: getSiteMapValueM(idToUse, "groupName"),
				parameterName: getSiteMapValueM(idToUse, "locationName"),
				warnAlarmLo: typeof warnAlarmLo === "number" ? warnAlarmLo : "",
				warnAlarmHi: typeof warnAlarmHi === "number" ? warnAlarmHi : "",
				mainAlarmLo: typeof mainAlarmLo === "number" ? mainAlarmLo : "",
				mainAlarmHi: typeof mainAlarmHi === "number" ? mainAlarmHi : "",
			});
		},
		[xId, yId, getSiteMapValueM, getLoHiM],
	);

	const saveEditAlarm = useCallback(
		async (data) => {
			await savePreference(`Psychometric_AlarmOverride_${data.id}`, JSON.stringify(data));
			setEditAlarmData(undefined);
		},
		[savePreference],
	);

	const resetEditAlarm = useCallback(
		async (id) => {
			await savePreference(`Psychometric_AlarmOverride_${id}`, null);
			setEditAlarmData(undefined);
		},
		[savePreference],
	);

	const addAlarmsToChartTitle = useCallback(() => {
		setOptions((o) => {
			o.subtitles.push({ text: `${getSiteMapValueM(xId, "locationName")}: ${local.TF_Alarm_Range}: ${getRangeM(true, true)}, ${local.TF_Threshold_Range}: ${getRangeM(false, true)}`, fontColor: "#606060", fontSize: 12, fontFamily: "Poppins, Arial" });
			o.subtitles.push({ text: `${getSiteMapValueM(yId, "locationName")}: ${local.TF_Alarm_Range}: ${getRangeM(true, false)}, ${local.TF_Threshold_Range}: ${getRangeM(false, false)}`, fontColor: "#606060", fontSize: 12, fontFamily: "Poppins, Arial" });
			return o;
		});
		ref.render();
	}, [ref, xId, yId, getSiteMapValueM, getRangeM]);

	const removeAlarmsFromChartTitle = useCallback(() => {
		setOptions((o) => {
			o.subtitles = [o.subtitles[0]];
			return o;
		});
		ref.render();
	}, [ref]);

	function SelectText(element) {
		var doc = document;
		if (doc.body.createTextRange) {
			var range = document.body.createTextRange();
			range.moveToElementText(element);
			range.select();
		} else if (window.getSelection) {
			var selection = window.getSelection();
			var selectionRange = document.createRange();
			selectionRange.selectNodeContents(element);
			selection.removeAllRanges();
			selection.addRange(selectionRange);
		}
	}

	const onPrint = useCallback(() => {
		addAlarmsToChartTitle();

		ref.print();

		removeAlarmsFromChartTitle();
	}, [ref, addAlarmsToChartTitle, removeAlarmsFromChartTitle]);

	const onExport = useCallback(() => {
		addAlarmsToChartTitle();

		const now = new Date();
		ref.exportChart({ format: "jpg", fileName: `ZoneChart_${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, 0)}${String(now.getDate()).padStart(2, "0")}_${String(now.getHours()).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}` });

		removeAlarmsFromChartTitle();
	}, [ref, addAlarmsToChartTitle, removeAlarmsFromChartTitle]);

	const onEmail = useCallback(() => {
		setShowEmail(true);
	}, []);

	const sendEmail = async (to, subject, body) => {
		addAlarmsToChartTitle();

		var canvasDataUrl = ref.exportChart({ format: "png", toDataURL: true });

		removeAlarmsFromChartTitle();

		const base64Loc = canvasDataUrl.indexOf("base64,");

		await SendEmail(to, subject, body, canvasDataUrl.substring(base64Loc + 7));

		setShowEmail(false);
	};

	const onCopy = useCallback(() => {
		addAlarmsToChartTitle();

		var canvasDataUrl = ref.exportChart({ format: "png", toDataURL: true });

		removeAlarmsFromChartTitle();

		var img = document.createElement("img");
		img.src = canvasDataUrl;

		var div = document.createElement("div");
		div.contentEditable = true;
		div.appendChild(img);
		document.body.appendChild(div);

		// do copy
		SelectText(div);
		document.execCommand("Copy");
		document.body.removeChild(div);
		toast.success(local.TF_item_was_copied);
	}, [ref, addAlarmsToChartTitle, removeAlarmsFromChartTitle]);

	const exportTable = () => {
		let csvRows = [];
		const separator = ",";
		const tableEle = tableRef.current;
		//only get direct children of the table in question (thead, tbody)
		Array.from(tableEle.children).forEach(function(node) {
			//using scope to only get direct tr of node
			node.querySelectorAll(":scope > tr").forEach(function(tr) {
				let csvLine = [];
				//again scope to only get direct children
				tr.querySelectorAll(":scope > td").forEach(function(td) {
					//clone as to not remove anything from original
					let copytd = td.cloneNode(true);
					let data;
					if (copytd.dataset.val) data = copytd.dataset.val.replace(/(\r\n|\n|\r)/gm, "");
					else {
						Array.from(copytd.children).forEach(function(remove) {
							//remove nested elements before getting text
							remove.parentNode.removeChild(remove);
						});
						data = copytd.textContent.replace(/(\r\n|\n|\r)/gm, "");
					}
					data = data
						.replace(/(\s\s)/gm, " ")
						.replace(/"/g, '""')
						.replace(/°/g, "deg");
					csvLine.push('"' + data + '"');
				});
				csvRows.push(csvLine.join(separator));
			});
		});
		var a = document.createElement("a");
		a.style = "display: none; visibility: hidden"; //safari needs visibility hidden
		a.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvRows.join("\n"));
		a.download = "testfile.csv";
		document.body.appendChild(a);
		a.click();
		a.remove();
	};

	return (
		<Card className={"mt-2 rounded-soft"}>
			{showLoading ? (
				<Loader />
			) : (
				<>
					<AlarmEdit data={editAlarmData} onCancel={() => setEditAlarmData(undefined)} onSave={saveEditAlarm} onReset={resetEditAlarm} />
					<EmailModal show={showEmail} sendEmail={sendEmail} cancel={() => setShowEmail(false)} />
					<AvForm model={{ xId, yId, period }} onValidSubmit={async (_e, _values) => {}} className="p-3">
						<Row className="text-center">
							<Col>
								<h5>{local.TF_XAxis}</h5>
							</Col>
							<Col>
								<h5>{local.TF_YAxis}</h5>
							</Col>
							<Col>
								<h5>{local.TF_TimeScales}</h5>
							</Col>
						</Row>
						<Row className="text-center">
							<Col>
								<AvField type="select" name="xId" onChange={(e) => onParameterChange("X", e.currentTarget.value)}>
									<option value="" />
									{siteMap
										.filter((x) => x.groupId === parseInt(selectedGroupId))
										.map((x, k) => (
											<option value={x.locationId} key={k}>
												{x.locationName}
											</option>
										))}
								</AvField>
							</Col>
							<Col>
								<AvField type="select" name="yId" onChange={(e) => onParameterChange("Y", e.currentTarget.value)}>
									<option value="" />
									{siteMap
										.filter((x) => x.groupId === parseInt(selectedGroupId))
										.map((x, k) => (
											<option value={x.locationId} key={k}>
												{x.locationName}
											</option>
										))}
								</AvField>
							</Col>
							<Col>
								<AvField type="select" name="period" onChange={(e) => onParameterChange("Period", e.currentTarget.value)}>
									<option value="Day">{local.TS_Day}</option>
									<option value="Week">{local.TS_Week}</option>
									<option value="Month">{local.TS_Month}</option>
									<option value="Year">{local.TS_Year}</option>
									<option value="Custom">{local.TF_Custom}</option>
								</AvField>
							</Col>
						</Row>
						{period === "Custom" && (
							<Row>
								<Col>
									<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={from} onBlur={(e) => onParameterChange("From", e)} onChange={(e) => onParameterChange("From", e)} closeOnSelect={false} input={true} locale={language} utc={true} />
								</Col>
								<Col>
									<Datetime timeFormat="HH:mm" dateFormat="DD MMM YYYY" value={to} onBlur={(e) => onParameterChange("To", e)} onChange={(e) => onParameterChange("To", e)} closeOnSelect={false} input={true} locale={language} utc={true} />
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={3}>
								<CustomInput type="checkbox" id="chkShowThresholds" checked={showThresholds} onChange={(e) => onParameterChange("ShowThresholds", e.currentTarget.checked)} label={local.TF_ShowThresholds} />
							</Col>
							<Col xs={3}>
								<CustomInput type="checkbox" id="chkShowStatistics" checked={showStatistics} onChange={(e) => onParameterChange("ShowStatistics", e.currentTarget.checked)} label={local.TF_ShowStatistics} />
							</Col>
							{(allowShowSafeAlarm || allowShowSafeThreshold) && (
								<Col xs={6} className="d-inline-flex">
									{local.TF_ShowSafeArea}:{allowShowSafeThreshold && <CustomInput className="ml-2" type="checkbox" id="chkShowSafeThreshold" checked={showSafeThreshold} onChange={(e) => onParameterChange("ShowSafeThreshold", e.currentTarget.checked)} label={local.TF_ShowSafeAreaThreshold} />}
									{allowShowSafeAlarm && <CustomInput className="ml-2" type="checkbox" id="chkShowSafeAlarm" checked={showSafeAlarm} onChange={(e) => onParameterChange("ShowSafeAlarm", e.currentTarget.checked)} label={local.TF_ShowSafeAreaAlarm} />}
								</Col>
							)}
						</Row>
						<div id="chart-div" style={{ minHeight: 500 }} className="py-3 px-2">
							{data && <CanvasJSChart containerProps={{ height: "100%", minHeight: "300px" }} options={options} onRef={(x) => setRef(x)} />}
						</div>
						<UncontrolledDropdown size="sm" direction="up" className="allow-overflow float-right">
							<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
								<FontAwesomeIcon icon="share-alt" />
								<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
							</DropdownToggle>
							<DropdownMenu className="menu-border-blue dropdown-menu">
								<DropdownItem onClick={() => onExport()}>
									<FontAwesomeIcon icon="download" /> {local.TS_Download}
								</DropdownItem>
								<DropdownItem onClick={() => onPrint()}>
									<FontAwesomeIcon icon="print" /> {local.TS_Print}
								</DropdownItem>
								<DropdownItem onClick={() => onCopy()}>
									<FontAwesomeIcon icon="copy" /> {local.TS_Copy}
								</DropdownItem>
								<DropdownItem onClick={() => onEmail()}>
									<FontAwesomeIcon icon="envelope-square" /> {local.TS_Email}
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
						{(xId || yId) && (
							<div className="mb-4 ml-5">
								<table>
									<tbody>
										{xId && (
											<tr className={getHasAlarmOverrideM(true) ? "text-danger" : ""}>
												<td>
													<FontAwesomeIcon className="text-primary cursor-pointer" icon={faEdit} onClick={() => startEditAlarm(true)} />
												</td>
												<td className="px-2" style={{ "color": siteMap.find((x) => x.locationId === parseInt(xId))?.parameterColourHex || "black" }}>
													{getSiteMapValueM(xId, "locationName")}:
												</td>
												<td className="px-2">
													{local.TF_Alarm_Range}: {getRangeM(true, true)}
												</td>
												<td className="px-2">
													{local.TF_Threshold_Range}: {getRangeM(false, true)}
												</td>
											</tr>
										)}
										{yId && (
											<tr className={getHasAlarmOverrideM(false) ? "text-danger" : ""}>
												<td>
													<FontAwesomeIcon className="text-primary cursor-pointer" icon={faEdit} onClick={() => startEditAlarm(false)} />
												</td>
												<td className="px-2" style={{ "color": siteMap.find((x) => x.locationId === parseInt(yId))?.parameterColourHex || "black" }}>
													{getSiteMapValueM(yId, "locationName")}:
												</td>
												<td className="px-2">
													{local.TF_Alarm_Range}: {getRangeM(true, false)}
												</td>
												<td className="px-2">
													{local.TF_Threshold_Range}: {getRangeM(false, false)}
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						)}
						{initialised && showStatistics && xId && yId && period && data && (
							<div>
								<h5>{local.TS_Statistics}</h5>
								<table width="100%" className="text-right table-left-col1 table-bordered" ref={tableRef}>
									<tbody>
										<tr>
											<td>{local.TS_Building}</td>
											<td>{getSiteMapValueM(xId, "buildingName")}</td>
											<td>{getSiteMapValueM(yId, "buildingName")}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TS_Zone}</td>
											<td>{getSiteMapValueM(xId, "zoneName")}</td>
											<td>{getSiteMapValueM(yId, "zoneName")}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TS_Group}</td>
											<td>{getSiteMapValueM(xId, "groupName")}</td>
											<td>{getSiteMapValueM(yId, "groupName")}</td>
											<th>{local.TF_Combined}</th>
										</tr>
										<tr>
											<td>{local.TS_Parameter}</td>
											<td>
												{getSiteMapValueM(xId, "locationName")} ({getDataValueM(true, "parameterUnits")})
											</td>
											<td>
												{getSiteMapValueM(yId, "locationName")} ({getDataValueM(false, "parameterUnits")})
											</td>
											<td>
												{getSiteMapValueM(xId, "locationName")} ({getDataValueM(true, "parameterUnits")}) | {getSiteMapValueM(yId, "locationName")} ({getDataValueM(false, "parameterUnits")})
											</td>
										</tr>
										<tr>
											<td>{local.TF_Start_of_data}</td>
											<td>{getStartOfDataM(true)}</td>
											<td>{getStartOfDataM(false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_End_of_data}</td>
											<td>{getEndOfDataM(true)}</td>
											<td>{getEndOfDataM(false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Duration}</td>
											<td>{getTimesM(true, true)?.totalTimeString}</td>
											<td>{getTimesM(true, false)?.totalTimeString}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Valid_Data}</td>
											<td>{getValidDataM(true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getValidDataM(false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Max_Value}</td>
											<td>{formatNumberM(getMaxDataM(true), true)}</td>
											<td>{formatNumberM(getMaxDataM(false), false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_of_First_Max_Value}</td>
											<td>{getMaxDataFirstTimeM(true)}</td>
											<td>{getMaxDataFirstTimeM(false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Min_Value}</td>
											<td>{formatNumberM(getMinDataM(true), true)}</td>
											<td>{formatNumberM(getMinDataM(false), false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_of_First_Min_Value}</td>
											<td>{getMinDataFirstTimeM(true)}</td>
											<td>{getMinDataFirstTimeM(false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Average}</td>
											<td>{formatNumberM(getAverageM(true), true)}</td>
											<td>{formatNumberM(getAverageM(false), false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Sum}</td>
											<td>{formatNumberM(getSumM(true), true)}</td>
											<td>{formatNumberM(getSumM(false), false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_StandardDeviation}</td>
											<td>{getStandardDeviationM(true)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
											<td>{getStandardDeviationM(false)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
											<td />
										</tr>
										<tr>
											<td colSpan={4}>&nbsp;</td>
										</tr>
										<tr>
											<td>{local.TF_Alarm_Range}</td>
											<td>{getRangeM(true, true)}</td>
											<td>{getRangeM(true, false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Readings_Within}</td>
											<td>{getReadingsWithinM(true, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsWithinM(true, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsWithinCombinedM(true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Within}</td>
											<td>{percent(getReadingsWithinM(true, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsWithinM(true, false), getValidDataM(false))}</td>
											<td>{percent(getReadingsWithinCombinedM(true), getValidDataM(false))}</td>
										</tr>
										<tr>
											<td>{local.TF_Time_Within}</td>
											<td>{getTimesM(true, true)?.withinTimeString}</td>
											<td>{getTimesM(true, false)?.withinTimeString}</td>
											<td>{getTimesCombinedM(true)?.withinTimeString}</td>
										</tr>
										<tr>
											<td>{local.TF_Readings_Outside}</td>
											<td>{getReadingsOutsideM(true, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsOutsideM(true, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsOutsideCombinedM(true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Outside}</td>
											<td>{percent(getReadingsOutsideM(true, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsOutsideM(true, false), getValidDataM(false))}</td>
											<td>{percent(getReadingsOutsideCombinedM(true), getValidDataM(false))}</td>
										</tr>
										<tr>
											<td>{local.TF_Time_Outside}</td>
											<td>{getTimesM(true, true)?.outsideTimeString}</td>
											<td>{getTimesM(true, false)?.outsideTimeString}</td>
											<td>{getTimesCombinedM(true)?.outsideTimeString}</td>
										</tr>
										<tr>
											<td>{local.TF_Readings_Below}</td>
											<td>{getReadingsBelowM(true, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsBelowM(true, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Below}</td>
											<td>{percent(getReadingsBelowM(true, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsBelowM(true, false), getValidDataM(false))}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_Below}</td>
											<td>{getTimesM(true, true)?.belowTimeString}</td>
											<td>{getTimesM(true, false)?.belowTimeString}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Readings_Above}</td>
											<td>{getReadingsAboveM(true, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsAboveM(true, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Above}</td>
											<td>{percent(getReadingsAboveM(true, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsAboveM(true, false), getValidDataM(false))}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_Above}</td>
											<td>{getTimesM(true, true)?.aboveTimeString}</td>
											<td>{getTimesM(true, false)?.aboveTimeString}</td>
											<td />
										</tr>
										<tr>
											<td colSpan={4}>&nbsp;</td>
										</tr>
										<tr>
											<td>{local.TF_Threshold_Range}</td>
											<td>{getRangeM(false, true)}</td>
											<td>{getRangeM(false, false)}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Readings_Within}</td>
											<td>{getReadingsWithinM(false, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsWithinM(false, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsWithinCombinedM(false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Within}</td>
											<td>{percent(getReadingsWithinM(false, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsWithinM(false, false), getValidDataM(false))}</td>
											<td>{percent(getReadingsWithinCombinedM(false), getValidDataM(false))}</td>
										</tr>
										<tr>
											<td>{local.TF_Time_Within}</td>
											<td>{getTimesM(false, true)?.withinTimeString}</td>
											<td>{getTimesM(false, false)?.withinTimeString}</td>
											<td>{getTimesCombinedM(false)?.withinTimeString}</td>
										</tr>
										<tr>
											<td>{local.TF_Readings_Outside}</td>
											<td>{getReadingsOutsideM(false, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsOutsideM(false, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsOutsideCombinedM(false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Outside}</td>
											<td>{percent(getReadingsOutsideM(false, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsOutsideM(false, false), getValidDataM(false))}</td>
											<td>{percent(getReadingsOutsideCombinedM(false), getValidDataM(false))}</td>
										</tr>
										<tr>
											<td>{local.TF_Time_Outside}</td>
											<td>{getTimesM(false, true)?.outsideTimeString}</td>
											<td>{getTimesM(false, false)?.outsideTimeString}</td>
											<td>{getTimesCombinedM(false)?.outsideTimeString}</td>
										</tr>
										<tr>
											<td>{local.TF_Readings_Below}</td>
											<td>{getReadingsBelowM(false, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsBelowM(false, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Below}</td>
											<td>{percent(getReadingsBelowM(false, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsBelowM(false, false), getValidDataM(false))}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_Below}</td>
											<td>{getTimesM(false, true)?.belowTimeString}</td>
											<td>{getTimesM(false, false)?.belowTimeString}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Readings_Above}</td>
											<td>{getReadingsAboveM(false, true)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td>{getReadingsAboveM(false, false)?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Percent_Readings_Above}</td>
											<td>{percent(getReadingsAboveM(false, true), getValidDataM(true))}</td>
											<td>{percent(getReadingsAboveM(false, false), getValidDataM(false))}</td>
											<td />
										</tr>
										<tr>
											<td>{local.TF_Time_Above}</td>
											<td>{getTimesM(false, true)?.aboveTimeString}</td>
											<td>{getTimesM(false, false)?.aboveTimeString}</td>
											<td />
										</tr>
									</tbody>
								</table>
								<UncontrolledDropdown size="sm" direction="up" className="allow-overflow float-right mt-1">
									<DropdownToggle caret color="info" transform="shrink-3" className={buttonDefaultClasses}>
										<FontAwesomeIcon icon="share-alt" />
										<span className="d-none d-md-inline-block ml-2">{local.TS_Options}</span>
									</DropdownToggle>
									<DropdownMenu className="menu-border-blue dropdown-menu">
										<DropdownItem onClick={exportTable}>
											<FontAwesomeIcon icon="download" /> {local.TS_Download}
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						)}
					</AvForm>
				</>
			)}
		</Card>
	);
};

export default withRouter(GroupChart);
