import { apiUrl, roles } from "../config";
import axios from "axios";
import jsonwebtoken from "jsonwebtoken";

const KEY_CurrentSiteId = "CurrentSiteId";
const KEY_CurrentDepartmentId = "CurrentDepartmentId";

export const Login = async (email, password) => {
	const result = await axios({
		method: "post",
		url: "v2/Login",
		baseURL: apiUrl(),
		data: {
			EmailAddress: email,
			Password: password,
		},
	});

	const data = result.data;

	if (data.successful) {
		CurrentUserStoreCreds(data);
		return true;
	}

	CurrentUserClearCreds();
	return false;
};

export const AccessSite = async(siteId, accessCode) => {
	const result = await axios({
		method: "post",
		url: "v2/AccessSite",
		baseURL: apiUrl(),
		data: {
			siteId,accessCode
		},
	});

	const data = result.data;

	if (data.successful) {
		CurrentUserStoreCreds(data, false);
		return true;
	}

	CurrentUserClearCreds();
	return false;
}

export const ForgotPassword = async (email) => {
	try {
		await axios({
			method: "post",
			url: "v2/PasswordForgot",
			baseURL: apiUrl(),
			data: {
				EmailAddress: email,
			},
		});
	} catch {
		//Do nothing
	}
};

export const ResetPassword = async (email, newPassword) => {
	const params = new URLSearchParams(window.location.search);
	const code = params.get("code");

	var result = {
		successful: false,
		errors: [],
	};

	await axios({
		method: "post",
		url: "v2/PasswordReset",
		baseURL: apiUrl(),
		data: {
			EmailAddress: email,
			ResetToken: code,
			NewPassword: newPassword,
		},
	})
		.then(function() {
			result.successful = true;
		})
		.catch(function(err) {
			
			result.successful = false;

			if (err.response.status === 404) {
				result.errors = undefined;
			}

			if (err.response.status === 400) {
				result.errors = err.response.data.Errors;
			}
		});

	return result;
};

export const Logout = () => {
	CurrentUserClearCreds();
};

export const CurrentUserStoreCreds = (data, navigateIfNoCurrentSite = true) => {
	localStorage.setItem("AccessToken", data.accessToken);
	localStorage.setItem("AccessTokenExpiry", data.expires);
	localStorage.setItem("RefreshToken", data.refreshToken);

	const currentSites = CurrentUserSites();

	//Deal with an invalid current site id
	let currentSiteId = localStorage.getItem(KEY_CurrentSiteId) || "";
	if (currentSiteId !== "" && !currentSites.includes(currentSiteId)) {
		localStorage.removeItem(KEY_CurrentSiteId);
		localStorage.removeItem(KEY_CurrentDepartmentId);
		currentSiteId = ""
	}

	if (navigateIfNoCurrentSite && currentSiteId === "") {
		switch(CurrentUserRole())
		{
			case roles.Eltek:
				window.location.href = '/admin'
				break;
			case roles.Distributor:
				window.location.href = '/distributor'
				break;
			default:
				if (currentSites.length === 0){
					CurrentUserClearCreds();
					window.location.href = "/authentication/login";
				}	
				localStorage.setItem(KEY_CurrentSiteId, currentSites[0]);
				break;
			}
	}

};

export const CurrentUserClearCreds = () => {
	localStorage.removeItem("AccessToken");
	localStorage.removeItem("AccessTokenExpiry");
	localStorage.removeItem("RefreshToken");
};

export const CurrentUserRole = () => {
	return CurrentUserField("http://schemas.microsoft.com/ws/2008/06/identity/claims/role");
};

export const CurrentUserEmailAddress = () => {
	return CurrentUserField("http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress");
};

export const CurrentUserSites = () => {
	return CurrentUserField("http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata").split("|");
};

export const CurrentSites = () => {
	const sites = CurrentUserField("sites");

	if (sites) {
		return sites.split("|");
	} else {
		return [];
	}
};

export const CurrentUserDisplayName = () => {
	return CurrentUserField("dn");
};

export const CurrentUserCompanyName = () => {
	return CurrentUserField("cn");
};

const CurrentUserField = (field) => {
	const accessToken = localStorage.getItem("AccessToken") || "";

	if (accessToken === "") {
		return "";
	}

	var decoded = jsonwebtoken.decode(accessToken);

	return decoded[field];
};

export const CurrentUserHasRole = (role) => {
	if (role === roles.Any) {
		if (CurrentUserEmailAddress() === "") {
			return false;
		}
		return true;
	}

	var userRole = CurrentUserRole();

	switch (role) {
		case roles.Eltek:
			return userRole === roles.Eltek;

		case roles.Distributor:
			return userRole === roles.Eltek || userRole === roles.Distributor;

		case roles.SiteAdmin:
			return userRole === roles.Eltek || userRole === roles.Distributor || userRole === roles.SiteAdmin;

		case roles.DepartmentAdmin:
			return userRole === roles.Eltek || userRole === roles.Distributor || userRole === roles.SiteAdmin || userRole === roles.DepartmentAdmin;

		case roles.SecondaryUser:
			return userRole === roles.Eltek || userRole === roles.Distributor || userRole === roles.SiteAdmin || userRole === roles.DepartmentAdmin || userRole === roles.SecondaryUser;

		default:
			return false;
	}
};
