import { CurrentSiteProductType } from "./api/userSettings";

export const version = "1.0.1.3";
export const navbarBreakPoint = "xl";

export const meterPollingInterval = 60000 * 5; //5m
export const dashboardRefreshInterval = 60000 * 5; //5m
export const dashboardRefreshValuesPollInterval = 1000 * 15; //15s
export const loggerStopStartRefreshValuesPollInterval = 1000 * 60; //1m

export const apiUrl = () => {
	switch (getEnvironment()) {
		case environments.Localhost:
			return "http://localhost:7071/api/";
		case environments.Test:
			return "https://darcatestapi.azurewebsites.net/api/";
		case environments.Demo:
			return "https://darcademoapi.azurewebsites.net/api/";
		case environments.Production:
			return "https://darcaprodapi.azurewebsites.net/api/";
		case environments.Pow:
			return "https://darcapowapi.azurewebsites.net/api/";
		case environments.KsaDev:
			return "https://dev-rcu-api.darcaconnect.com/api/";
		case environments.KsaProd:
			return "https://rcu-api.darcaconnect.com/api/";
		case environments.Development:
		default:
			return "https://dev-dcapi-awfp4su64dcww.azurewebsites.net/api/";
	}
};

export const gaTrackingId = () => {
	switch (getEnvironment()) {
		case environments.Test:
			return "UA-154455426-1";
		case environments.Demo:
			return "UA-154455426-4";
		case environments.Production:
		case environments.Pow:
		case environments.KsaProd:
			return "UA-154455426-3";
		case environments.Localhost:
		case environments.Development:
		case environments.KsaDev:
		default:
			return "UA-154455426-2";
	}
};

export const roles = {
	Eltek: "Eltek",
	Distributor: "Distributor",
	SiteAdmin: "SiteAdmin",
	DepartmentAdmin: "DepartmentAdmin",
	SecondaryUser: "SecondaryUser",
	DormantUser: "DormantUser",
	Any: "Any",
};

export const defaultDistributorDetails = {
	name: "Eltek Ltd",
	tel: "+44 (0)1223 872111",
	email: "support@eltekdataloggers.co.uk",
};

export const environments = {
	Localhost: "Localhost",
	Development: "Development",
	Test: "Test",
	Demo: "Demo",
	Production: "Production",
	Pow: "Pow",
	KsaDev: "KsaDev",
	KsaProd: "KsaProd",
};

export const productTypes = {
	Lite: "Lite",
	Standard: "Standard",
	Plus: "Plus",
	Pro: "Pro",
};

export const getEnvironment = () => {
	const hostname = window && window.location && window.location.hostname;

	if (process.env.REACT_APP_STAGE) {
		if (process.env.REACT_APP_STAGE.trim() === "localhost") {
			return environments.Localhost;
		}
	}

	switch (hostname) {
		case "darcatestcdn.azureedge.net":
		case "test.darcaconnect.com":
			return environments.Test;

		case "darcademocdn.azureedge.net":
		case "demo.darcaconnect.com":
			return environments.Demo;

		case "darcaprodcdn.azureedge.net":
		case "www.darcaconnect.com":
		case "darcaconnect.com":
			return environments.Production;

		case "darcapowcdn.azureedge.net":
		case "pow.darcaconnect.com":
			return environments.Pow;

		case "dev-rcu.darcaconnect.com":
			return environments.KsaDev;
		case "rcu.darcaconnect.com":
			return environments.KsaProd;

		default:
			return environments.Development;
	}
};

export const features = {
	AlarmHistoryReport: "AlarmHistoryReport",
	CalibrationSchedule: "CalibrationSchedule",
	ChartReportOverlays: "ChartReportOverlays",
	ConfigureAlarms: "ConfigureAlarms",
	ConfigureSMSAlarms: "ConfigureSMSAlarms",
	CurrentAlarms: "CurrentAlarms",
	DarcaHubOfflineAlarm: "DarcaHubOfflineAlarm",
	DatabaseSettings: "DatabaseSettings",
	DemoCreate: "DemoCreate",
	Departments: "Departments",
	DisplayLoggerChannelNames: "DisplayLoggerChannelNames",
	EditUserProfile: "EditUserProfile",
	EmailReports: "EmailReports",
	EmailReportsChartReport: "EmailReportsChartReport",
	EmailReportsFluctuation: "EmailReportsFluctuation",
	EmailReportsHardware: "EmailReportsHardware",
	EmailReportsMultiPeriod: "EmailReportsMultiPeriod",
	EmailReportsPerformanceStatistics: "EmailReportsPerformanceStatistics",
	EmailReportsStatistics: "EmailReportsStatistics",
	GeneralSettings: "GeneralSettings",
	GroupNameEdit: "GroupNameEdit",
	HasStorage: "HasStorage",
	ImportOptions: "ImportOptions",
	Localisation: "Localisation",
	LocationAlarms: "LocationAlarms",
	LoggerConfiguration: "LoggerConfiguration",
	LoggerSQIConfiguration: "LoggerSQIConfiguration",
	LoggerStopStart: "LoggerStopStart",
	MultiPeriodReport: "MultiPeriodReport",
	MultiPeriodReportCopy: "MultiPeriodReportCopy",
	MultiPeriodReportDownload: "MultiPeriodReportDownload",
	MultiPeriodReportEmail: "MultiPeriodReportEmail",
	MultiPeriodReportPrint: "MultiPeriodReportPrint",
	MultipleSiteAdmins: "MultipleSiteAdmins",
	ParameterAdd: "ParameterAdd",
	ParameterEdit: "ParameterEdit",
	PsychometricChart: "PsychometricChart",
	RefreshValues: "RefreshValues",
	ReportEmail: "ReportEmail",
	Search: "Search",
	Seconds: "ShowReadingSeconds",
	SettingsAutomaticUpdates: "SettingsAutomaticUpdates",
	SettingsWizards: "SettingsWizards",
	SiteCreate: "SiteCreate",
	SiteEdit: "SiteEdit",
	SiteLayout: "SiteLayout",
	SiteManagement: "SiteManagement",
	SMSCloudRelayEmailAlarms: "SMSCloudRelayEmailAlarms",
	SMSCloudRelayMuting: "SMSCloudRelayMuting",
	UserMaintenanceDisableForSiteAdmins: "UserMaintenanceDisableForSiteAdmins",
	ZoneChartsEmail: "ZoneChartsEmail",
	ZoneGraphicsCopy: "ZoneGraphicsCopy",
	ZoneGraphicsDownload: "ZoneGraphicsDownload",
	ZoneGraphicsEmail: "ZoneGraphicsEmail",
	ZoneGraphicsSetup: "ZoneGraphicsSetup",
};

const eLocal = environments.Localhost;
const eDev = environments.Development;
const eTest = environments.Test;
const eDemo = environments.Demo;
const eAll = "AllEnvironments";
const pLite = productTypes.Lite;
//Additional for later, if needed
// const eProd = environments.Production;
// const ePow = environments.Pow;
// const pStandard = productTypes.Standard;
// const pPlus = productTypes.Plus;
// const pPro = productTypes.Pro;

// prettier-ignore
const featureRules = [
	{ feature: features.AlarmHistoryReport,					enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.CalibrationSchedule, 				enabledIn: [], 									disabledIn: [] }, 
	{ feature: features.ChartReportOverlays, 				enabledIn: [eLocal, eDev], 						disabledIn: [] },
	{ feature: features.CurrentAlarms, 						enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.ConfigureAlarms,					enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.ConfigureSMSAlarms,					enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.DarcaHubOfflineAlarm, 				enabledIn: [eLocal, eDev, eTest],				disabledIn: [] },
	{ feature: features.DatabaseSettings, 					enabledIn: [], 									disabledIn: [] },
	{ feature: features.DemoCreate, 						enabledIn: [eDemo], 							disabledIn: [] },
	{ feature: features.Departments, 						enabledIn: [eAll],						 		disabledIn: [pLite] },
	{ feature: features.DisplayLoggerChannelNames,			enabledIn: [],									disabledIn: [] },
	{ feature: features.EditUserProfile, 					enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.EmailReports, 						enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.EmailReportsChartReport, 			enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.EmailReportsFluctuation, 			enabledIn: [], 									disabledIn: [] },
	{ feature: features.EmailReportsHardware, 				enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.EmailReportsMultiPeriod, 			enabledIn: [eLocal, eDev, eTest], 				disabledIn: [] },
	{ feature: features.EmailReportsPerformanceStatistics, 	enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.EmailReportsStatistics, 			enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.LatestValuesTableOptions,			enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.GeneralSettings, 					enabledIn: [eLocal, eDev, eTest],				disabledIn: [] },
	{ feature: features.GroupNameEdit, 						enabledIn: [], 									disabledIn: [] },
	{ feature: features.HasStorage, 						enabledIn: [eAll],								disabledIn: [] },
	{ feature: features.ImportOptions, 						enabledIn: [], 									disabledIn: [] },
	{ feature: features.Localisation, 						enabledIn: [eLocal, eDev], 						disabledIn: [] },
	{ feature: features.LocationAlarms, 					enabledIn: [eAll],		 						disabledIn: [] },
	{ feature: features.LoggerConfiguration, 				enabledIn: [eLocal, eDev, eTest],				disabledIn: [] },
	{ feature: features.LoggerSQIConfiguration,				enabledIn: [],									disabledIn: [] },
	{ feature: features.LoggerStopStart,					enabledIn: [],									disabledIn: [] },
	{ feature: features.MultiPeriodReport, 					enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.MultiPeriodReportCopy, 				enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.MultiPeriodReportDownload, 			enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.MultiPeriodReportEmail, 			enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.MultiPeriodReportPrint, 			enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.MultipleSiteAdmins, 				enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.ParameterAdd,						enabledIn: [],									disabledIn: []},
	{ feature: features.ParameterEdit,						enabledIn: [eAll],								disabledIn: []},
	{ feature: features.PsychometricChart,					enabledIn: [eLocal],							disabledIn: [] },
	{ feature: features.RefreshValues, 						enabledIn: [], 									disabledIn: [] },
	{ feature: features.ReportEmail, 						enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.Search, 							enabledIn: [eLocal, eDev], 						disabledIn: [] },
	{ feature: features.Seconds,							enabledIn: [],									disabledIn: [] },
	{ feature: features.SettingsAutomaticUpdates, 			enabledIn: [eLocal, eDev, eTest],				disabledIn: [] },
	{ feature: features.SettingsWizards, 					enabledIn: [], 									disabledIn: [] },
	{ feature: features.SiteCreate, 						enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.SiteEdit, 							enabledIn: [],			 						disabledIn: [] },
	{ feature: features.SiteLayout, 						enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.SiteManagement, 					enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.SMSCloudRelayEmailAlarms,			enabledIn: [],									disabledIn: [] },
	{ feature: features.SMSCloudRelayMuting,				enabledIn: [], 									disabledIn: [] },
	{ feature: features.UserMaintenanceDisableForSiteAdmins,enabledIn: [],									disabledIn: [] },
	{ feature: features.ZoneChartsEmail, 					enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.ZoneGraphicsCopy, 					enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.ZoneGraphicsDownload, 				enabledIn: [eAll], 								disabledIn: [] },
	{ feature: features.ZoneGraphicsEmail, 					enabledIn: [eAll], 								disabledIn: [pLite] },
	{ feature: features.ZoneGraphicsSetup, 					enabledIn: [eLocal, eDev], 						disabledIn: [] },
];

export const isFeatureEnabled = (feature) => {
	let localSiteFeatures = localStorage.getItem("SiteFeatures");
	if (localSiteFeatures === "undefined") {
		localSiteFeatures = undefined;
	}
	const dbFeatures = JSON.parse(localSiteFeatures || "[]");
	if (dbFeatures.includes(feature)) {
		return true;
	}
	if (dbFeatures.includes(`DISABLED:${feature}`)) {
		return false;
	}

	const rules = featureRules.find((x) => x.feature === feature);

	//Can't find a rule, then feature is off
	if (!rules) {
		return false;
	}

	//Feature disabled for current product?
	if (rules.disabledIn.includes(CurrentSiteProductType())) {
		return false;
	}

	//Feature allowed for all environments or for the current environment
	return rules.enabledIn.includes(eAll) || rules.enabledIn.includes(getEnvironment());
};
export const configSiteAnnualSmsSegmentLimit = 3000;
