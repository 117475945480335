import { faToggleOff, faToggleOn } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, Col, Row } from "reactstrap";
import { apiConfigureLoggerSMSNumbers, apiOutstandingLoggerSMSNumbersConfiguration, apiSquirrelSMSNumbersGetList } from "../../../api/apiLoggerConfiguration";
import { apiSquirrelUpdateDarcaHubOfflineNotify, apiSquirrelsGetList } from "../../../api/apiSquirrels";
import { apiSiteSettingsList } from "../../../api/site";
import { features, isFeatureEnabled } from "../../../config";
import { dynamicSort } from "../../../helpers/sort";
import { tableIcons } from "../../../helpers/tableIcons";
import local from "../../../localization/strings";
import PageTitle from "../../common/PageTitle";

const LoggerSMSNumbers = ({ match, history }) => {
	const [data, setData] = useState();
	const [pending, setPending] = useState();
	const [squirrel, setSquirrel] = useState();
	const [error, setError] = useState(undefined);
	const [maxNumber, setMaxNumbers] = useState(10);
	const [groups, setGroups] = useState(false);
	const [readonly, setReadonly] = useState(false);
	const tableRef = useRef();
	const sMSCloudRelayEmailAlarmsFeatureEnabled = isFeatureEnabled(features.SMSCloudRelayEmailAlarms);
	const darcaHubOfflineAlarmFeatureEnabled = isFeatureEnabled(features.DarcaHubOfflineAlarm);

	const columns = [
		{
			field: "phoneIndex", //
			title: local.TF_Phone_Index,
			defaultSort: "asc",
			hidden: true,
		},
		{ field: "phoneNumber", title: local.TS_Phone_Number },
		{ field: "email", title: local.TS_Email, hidden: !sMSCloudRelayEmailAlarmsFeatureEnabled },
		{ field: "description", title: local.TS_Description },
		{ field: "group", hidden: !groups, title: local.TF_Group_Name, lookup: { " ": "All", "A": "A", "B": "B", "C": "C", "D": "D", "E": "E", "F": "F", "G": "G", "H": "H", "I": "I", "J": "J" } },
	];
	const pendingColumns = [
		{
			field: "PhoneIndex", //
			title: local.TF_Phone_Index,
			defaultSort: "asc",
			hidden: true,
		},
		{ field: "PhoneNumber", title: local.TS_Phone_Number },
		{ field: "Email", title: local.TS_Email, hidden: !sMSCloudRelayEmailAlarmsFeatureEnabled },
		{ field: "Description", title: local.TS_Description },
		{ field: "Group", hidden: !groups, title: local.TF_Group_Name, lookup: { " ": "All", "A": "A", "B": "B", "C": "C", "D": "D", "E": "E", "F": "F", "G": "G", "H": "H", "I": "I", "J": "J" } },
	];

	useEffect(() => {
		const loadData = async () => {
			const sn = match?.params?.serialNumber;

			const squirrels = await apiSquirrelsGetList();
			const foundSquirrel = squirrels.find((x) => x.serialNumber === sn);
			setSquirrel(foundSquirrel);

			const pendingConfig = await apiOutstandingLoggerSMSNumbersConfiguration(sn);
			if (pendingConfig?.data) {
				setReadonly(true);
				setPending(pendingConfig.data?.SMSNumbers);
			} else {
				setReadonly(false);
			}

			const siteSettingsResult = await apiSiteSettingsList();
			if (siteSettingsResult.find((x) => x.settingKey === `${sn}/NoConnection` && x.settingValue === "0")) {
				//No Action
			} else {
				setReadonly(true);
			}

			//Grouping
			if (getBit(foundSquirrel.specialDarca, 1) === 1) {
				setGroups(true);
				//Extended Grouping
				if (getBit(foundSquirrel.specialDarca, 2) === 1) {
					setMaxNumbers(20);
				} else {
					setMaxNumbers(10);
				}
			} else {
				setMaxNumbers(10);
				setGroups(false);
			}

			const smsResult = await apiSquirrelSMSNumbersGetList(sn);
			smsResult.forEach((e) => {
				if (!e.email) {
					e.email = "";
				}
			});
			setData(smsResult);
		};

		loadData();
	}, [match]);

	const onSave = useCallback(
		async (dataToSave) => {
			if (tableRef.current.state.showAddRow || tableRef.current.state.lastEditingRow) {
				setError(local.TF_Edit_Inprogress);
				return;
			}

			for (const item of dataToSave) {
				if (!item.phoneNumber) {
					setError(local.TF_Phone_Number_Missing);
					return;
				}
				if (!(item.phoneNumber || "").match(/^[+][1-9][0-9]{6,15}$/)) {
					setError(local.TF_Phone_Number_Invalid);
					return;
				}
				if ((item.phoneNumber || "").length > 20) {
					setError(local.TF_Phone_Number_TooLong);
					return;
				}
				if (sMSCloudRelayEmailAlarmsFeatureEnabled) {
					if ((item.email || "").length > 254) {
						setError(local.TF_Email_TooLong);
						return;
					}
					//https://www.regular-expressions.info/email.html
					if (item.email && !item.email.match(/^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i)) {
						setError(local.TF_Email_Invalid);
						return;
					}
				}
				if ((item.description || "").length > 20) {
					setError(local.TF_Phone_Description_TooLong);
					return;
				}
				if (groups && !item.group) {
					setError(local.TF_Group_Missing);
					return;
				}
			}

			dataToSave.sort(dynamicSort("phoneIndex"));

			await apiConfigureLoggerSMSNumbers(
				squirrel.serialNumber,
				dataToSave.map((x, key) => {
					return { phoneIndex: key, phoneNumber: x.phoneNumber, email: x.email ? x.email : null, description: x.description || null, group: x.group };
				}),
			);

			history.push(`/site_settings/logger/${squirrel.serialNumber}`);
		},
		[groups, history, squirrel, sMSCloudRelayEmailAlarmsFeatureEnabled],
	);

	function getBit(number, bitPosition) {
		return (number & (1 << bitPosition)) === 0 ? 0 : 1;
	}

	const updateDarcaHubOfflineNotify = useCallback(
		async (newValue) => {
			setSquirrel((x) => {
				return { ...x, darcaHubOfflineNotify: newValue };
			});
			await apiSquirrelUpdateDarcaHubOfflineNotify(match?.params?.serialNumber, newValue);
		},
		[match],
	);

	return (
		<>
			<PageTitle title={`${local.TF_SMS_Numbers}: ${match?.params?.serialNumber || ""}`} />
			{data && (
				<>
					<div className="mt-4 MuiTable-SmallHeader">
						<MaterialTable
							columns={columns}
							tableRef={tableRef}
							editable={{
								onRowAdd:
									readonly || (data?.length || 0) >= maxNumber
										? undefined
										: (newData) =>
												new Promise((resolve, _reject) => {
													setTimeout(() => {
														const phoneIndex = data.length > 0 ? Math.max(...data.map((o) => o.phoneIndex)) + 1 : 0;
														newData.phoneIndex = phoneIndex;
														setData([...data, newData]);
														setError();
														resolve();
													}, 1000);
												}),
								onRowUpdate: readonly
									? undefined
									: (newData, oldData) =>
											new Promise((resolve, _reject) => {
												setTimeout(() => {
													const phoneIndex = oldData.phoneIndex;
													newData.phoneIndex = phoneIndex;
													setData([...data.filter((x) => x.phoneIndex !== phoneIndex), newData]);
													setError();
													resolve();
												}, 1000);
											}),
								onRowDelete: readonly
									? undefined
									: (oldData) =>
											new Promise((resolve, _reject) => {
												setTimeout(() => {
													const phoneIndex = oldData.phoneIndex;
													setData([...data.filter((x) => x.phoneIndex !== phoneIndex)]);
													setError();
													resolve();
												}, 1000);
											}),
							}}
							data={data}
							title={pending ? local.TF_Current_Values : ""}
							icons={tableIcons}
							options={{
								search: false,
								sorting: true,
								paging: false,
								padding: "dense",
								emptyRowsWhenPaging: false,
								showEmptyDataSourceMessage: false,
								headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" },
							}}
						/>
					</div>
					{pending && (
						<div className="mt-4 MuiTable-SmallHeader">
							<MaterialTable
								columns={pendingColumns}
								data={pending}
								title={local.TF_Pending_Changes}
								icons={tableIcons}
								options={{
									search: false,
									sorting: true,
									paging: false,
									padding: "dense",
									emptyRowsWhenPaging: false,
									showEmptyDataSourceMessage: false,
									headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" },
								}}
							/>
						</div>
					)}
					{error && (
						<Alert color="danger" className="mt-2 mb-0">
							{error}
						</Alert>
					)}
					{darcaHubOfflineAlarmFeatureEnabled && (
						<Card className="p-2 mt-2">
							<h5 className="text-primary">{local.TF_Notifications}</h5>
							<div className="d-flex flex-row align-items-center">
								<span className="font-weight-bold mr-2">{local.TF_Darca_hub_offline}:</span>
								<FontAwesomeIcon
									className="text-secondary cursor-pointer"
									icon={squirrel.darcaHubOfflineNotify ? faToggleOn : faToggleOff}
									size="2x"
									onClick={() => {
										updateDarcaHubOfflineNotify(squirrel.darcaHubOfflineNotify ? false : true);
									}}
									title={squirrel.darcaHubOfflineNotify ? local.TF_Notify : local.TF_Notify_Off}
								/>
							</div>
						</Card>
					)}
					<Row>
						<Col>
							{pending || readonly ? (
								<Button type="button" color="secondary" className="mt-2 ml-2" onClick={() => history.push(`/site_settings/logger/${match?.params?.serialNumber}`)}>
									{local.TS_Close}
								</Button>
							) : (
								<>
									<Button type="button" color="primary" className="mt-2" onClick={() => onSave(data)}>
										{local.TS_Save_Changes}
									</Button>
									<Button type="button" color="secondary" className="mt-2 ml-2" onClick={() => history.push(`/site_settings/logger/${match?.params?.serialNumber}`)}>
										{local.TS_Cancel}
									</Button>
								</>
							)}
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default withRouter(LoggerSMSNumbers);
