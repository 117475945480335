import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Card, Col, Row } from "reactstrap";
import { CurrentUserHasRole } from "../../api/auth";
import { readingsLatest, siteRefreshValuesRequest, siteRefreshValuesRequestOutstanding } from "../../api/readings";
import { dashboardRefreshInterval, dashboardRefreshValuesPollInterval, environments, features, getEnvironment, isFeatureEnabled, roles } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import useInterval from "../../hooks/useInterval";
import local from "../../localization/strings";
import PageTitle from "../common/PageTitle";
import BatteryLevels from "./BatteryLevels";
import DashWidgetDropdown from "./DashWidgetDropdown";
import DashChart from "./DashboardComponents/Charts/DashChart";
import DashChartBlue from "./DashboardComponents/Charts/DashChartBlue";
import MultiChart from "./DashboardComponents/Charts/MultiChart";
import DashMeter from "./DashboardComponents/Meters/DashMeter";
import DashMeterBlock from "./DashboardComponents/Meters/DashMeterBlock";
import DashMeterBlockBlue from "./DashboardComponents/Meters/DashMeterBlockBlue";
import DashMeterBlue from "./DashboardComponents/Meters/DashMeterBlue";
import BatteryLevelDoughnut from "./DashboardComponents/Stats/BatteryLevelDoughnut";
import BatteryLevelTable from "./DashboardComponents/Stats/BatteryLevelTable";
import DashStatsContainer from "./DashboardComponents/Stats/DashStatsContainer";
import DashZoneGraphic from "./DashboardComponents/ZoneGraphics/DashZoneGraphic";
import DashZoneGraphicWide from "./DashboardComponents/ZoneGraphics/DashZoneGraphicWide";
import DashboardEdit from "./DashboardEdit";
import LatestValues from "./LatestValues";

const Dashboard = () => {
	const [connectionError, setConnectionError] = useState(false);
	const [sortable, setSortable] = useState(false);
	const [batteriesTable, setBatteriesTable] = useState(false);
	const [batteryData, setBatteryData] = useState([]);
	const { dashboardLayout, dashboardLayoutLoaded, siteName, newReleaseNotes, savePreferenceCallback } = useContext(UserPreferencesContext);
	const [latestReadings, setLatestReadings] = useState(undefined);
	const [refreshingValues, setRefreshingValues] = useState(false);

	const refreshValues = async () => {
		const locationIds = [];

		dashboardLayout.forEach((item) => {
			const parts = item.split("|");

			switch (parts[0]) {
				case "DashMeter":
				case "DashMeterBlue":
				case "DashMeterBlock":
				case "DashMeterBlockBlue":
				case "DashChart":
				case "DashChartBlue":
				case "DashZoneGraphic":
				case "DashZoneGraphicWide":
					locationIds.push(parts[1]);
					break;
				default:
					break;
			}
		});

		logEvent("Dashboard", "Refresh Values Clicked");
		setRefreshingValues(true);
		await siteRefreshValuesRequest(undefined, locationIds);
	};

	const toggleSortable = () => {
		logEvent("Dashboard", "Edit Clicked");
		setSortable(true);
	};

	const viewBatteries = useCallback((data) => {
		setBatteriesTable((b) => !b);
		setBatteryData(data);
	}, []);

	const LoadLatestValues = useCallback(async () => {
		var result = await readingsLatest(null, true, false);

		if (result) {
			if (dashboardLayoutLoaded) {
				const toRemove = [];

				dashboardLayout.forEach((item) => {
					const parts = item.split("|");

					switch (parts[0]) {
						case "LatestValues":
						case "MultiChart":
						case "DashChart":
						case "DashChartBlue":
						case "DashMeter":
						case "DashMeterBlue":
						case "DashMeterBlock":
						case "DashMeterBlockBlue":
						case "DashZoneGraphic":
						case "DashZoneGraphicWide":
						case "DashStatsContainer":
						case "DashBatteryDoughnut":
						case "DashBatteryTable":
							// All fine
							break;
						default:
							toRemove.push(item);
							if (getEnvironment() === environments.Localhost) {
								console.warn(`Dashboard Widget: Found unknown type: ${parts[0]}`);
							}
							break;
					}
				});

				if (toRemove.length > 0) {
					const newLayout = dashboardLayout.filter((x) => !toRemove.includes(x));
					await savePreferenceCallback("Dashboard", JSON.stringify(newLayout), true);
				}
			}

			setLatestReadings(result);
			setConnectionError(false);
		} else {
			setConnectionError(true);
		}
	}, [dashboardLayout, dashboardLayoutLoaded, savePreferenceCallback]);

	useInterval(async () => {
		await LoadLatestValues();
	}, dashboardRefreshInterval);

	const checkRefreshingValues = useCallback(
		async (initial = false) => {
			if (!CurrentUserHasRole(roles.SiteAdmin) || !isFeatureEnabled(features.RefreshValues)) {
				setRefreshingValues(false);
				return;
			}

			const isRefreshing = await siteRefreshValuesRequestOutstanding();

			if (isRefreshing) {
				setRefreshingValues(true);
			} else {
				if (!initial) {
					setRefreshingValues(false);
					await LoadLatestValues();
				}
			}
		},
		[LoadLatestValues],
	);

	useInterval(async () => {
		if (refreshingValues) {
			await checkRefreshingValues();
		}
	}, dashboardRefreshValuesPollInterval);

	useEffect(() => {
		checkRefreshingValues(true);
		LoadLatestValues();
	}, [LoadLatestValues, checkRefreshingValues]);

	return (
		<>
			{latestReadings && siteName && (
				<>
					<PageTitle title={siteName} />
					{newReleaseNotes && (
						<Link to="/release-notes">
							<Alert color="success">{local.TS_New_Release_Notes}</Alert>
						</Link>
					)}
					{(latestReadings || []).find((x) => x.darcaHubOffline) && <Alert color="danger">{local.TF_Darca_Hub_Offline_Warning}</Alert>}
					{connectionError && <Alert color="danger">{local.TF_ConnectionError}</Alert>}

					{sortable ? (
						<DashboardEdit setSortable={setSortable} />
					) : batteriesTable ? (
						<BatteryLevels data={batteryData} viewBatteries={viewBatteries} />
					) : (
						<>
							{dashboardLayout.length === 0 && dashboardLayoutLoaded && (
								<Card className="dash-card p-4 w-100 my-3">
									<p>
										{local.TF_This_is_your_dashboard} <Link to="/metering">{local.TS_LatestValues}</Link>, <Link to="/charts/zone_charts">{local.TS_Charts}</Link> {local.TS_or} <Link to="/zone_graphics">{local.TS_ZoneGraphics}</Link>. {local.TF_Look_for_the} <FontAwesomeIcon icon="ellipsis-v" /> {local.TF_icon_and_pin}
									</p>
									<p>{local.TF_Rearrange_and_delete}</p>
								</Card>
							)}
							<Row>
								{dashboardLayout.map((item, index) => {
									const parts = item.split("|");

									switch (parts[0]) {
										case "LatestValues":
											return <LatestValues key={index} config={item} />;
										case "DashMeter":
											return <DashMeter key={index} locationId={parts[1]} latestReadings={latestReadings} />;
										case "DashMeterBlue":
											return <DashMeterBlue key={index} locationId={parts[1]} latestReadings={latestReadings} />;
										case "DashMeterBlock":
											return <DashMeterBlock key={index} locationId={parts[1]} latestReadings={latestReadings} />;
										case "DashMeterBlockBlue":
											return <DashMeterBlockBlue key={index} locationId={parts[1]} latestReadings={latestReadings} />;
										case "MultiChart":
											return <MultiChart key={index} locationIds={parts[1]} period={parts.length > 2 ? parts[2] || "Day" : "Day"} />;
										case "DashChart":
											return <DashChart key={index} locationId={parts[1]} period={parts.length > 2 ? parts[2] || "1Day" : "1Day"} />;
										case "DashChartBlue":
											return <DashChartBlue key={index} locationId={parts[1]} period={parts.length > 2 ? parts[2] || "1Day" : "1Day"} />;
										case "DashZoneGraphic":
											return <DashZoneGraphic key={index} locationId={parts[1]} />;
										case "DashZoneGraphicWide":
											return <DashZoneGraphicWide key={index} locationId={parts[1]} />;
										case "DashStatsContainer":
											return <DashStatsContainer key={index} locationId={parts[1]} />;
										case "DashBatteryDoughnut":
											return <BatteryLevelDoughnut key={index} viewBatteries={viewBatteries} latestReadings={latestReadings} />;
										case "DashBatteryTable":
											return <BatteryLevelTable key={index} latestReadings={latestReadings} config={item} />;
										default:
											return null;
									}
								})}
							</Row>
							<Row className="mb-6 mt-2">
								<Col>
									<DashWidgetDropdown />
								</Col>
								{isFeatureEnabled(features.RefreshValues) && CurrentUserHasRole(roles.SiteAdmin) && (
									<Col className="text-center">
										<Button onClick={refreshValues} color="falcon-warning" disabled={refreshingValues}>
											{refreshingValues ? local.TF_Refreshing_Values : local.TF_Refresh_Values}
										</Button>
									</Col>
								)}
								<Col>
									<Button onClick={toggleSortable} color="falcon-info" className="float-right">
										{local.TF_Edit_Dashboard}
									</Button>
								</Col>
							</Row>
						</>
					)}
				</>
			)}
		</>
	);
};

export default Dashboard;
