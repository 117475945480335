import axios from "axios";

export const apiReleaseNotesGetList = async () => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/ReleaseNotes",
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiReleaseNoteGet = async (filename) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/ReleaseNote",
			params: { filename },
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};
