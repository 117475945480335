import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Alert, CardDeck } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import local from "../../localization/strings";
import PageTitle from "../common/PageTitle";
import { isFeatureEnabled, features, roles } from "../../config";
import { CurrentUserHasRole } from "../../api/auth";

// alertType = danger, success etc - used for styling

function Notification(alertType, title, content) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Row>
			<Col className="ml-2">
				<Alert color={alertType} isOpen={isOpen} toggle={() => setIsOpen(false)}>
					<h4 className="alert-heading">{title}</h4>
					<p className="mb-0">{content}</p>
				</Alert>
			</Col>
		</Row>
	);
}

// type = wizard, block or minor style
//          wizard style has dashed outline and plus icon as default and no description - title only
//          block style has fontawesome icon, title & description
//          minor style has white bg, no icon and no description - title only

const MenuWizard = ({ title, route }) => {
	return (
		<Col className="col-12 col-md-4 d-flex align-items-stretch">
			<Link className="wizard-card card btn btn-falcon-primary mb-3 ml-2 block-flex" to={route}>
				<CardBody>
					<Row>
						<div className="col-1 col-md-12 col-lg-1">
							<FontAwesomeIcon icon="plus-circle" className="wizard-plus" />
						</div>
						<div className="col mt-1">
							<span>{title}</span>
						</div>
					</Row>
				</CardBody>
			</Link>
		</Col>
	);
};

const MenuBlock = ({ icon, title, description, route }) => {
	//Conditional rendering of button text (view/change settings) based on user role
	//Add feature switch so that changes don't appear in Test upwards for now
	return (
		<Col className="col-12 col-md-4 d-flex align-items-stretch">
			<Card className="mb-3 text-center ml-2 block-flex">
				<span className="card-img-top settings-header" alt={title}>
					<FontAwesomeIcon icon={icon} />
				</span>
				<CardBody>
					<h5 className="card-title menu-block-title">{title}</h5>
					<p className="card-text font-italic menu-block-description">{description}</p>
					<Link to={route} className="btn btn-primary text-white">
						{CurrentUserHasRole(roles.SiteAdmin) ? local.TF_Change_Settings : local.TS_View}
					</Link>
				</CardBody>
			</Card>
		</Col>
	);
};

const MenuMinor = ({ title, route }) => {
	return (
		<Col className="col-6 col-md-3 col-lg-3 d-flex align-items-stretch">
			<Link to={route} className="menu-minor">
				<Card className="btn block-flex mx-3">
					<p className="advanced-options">{title}</p>
				</Card>
			</Link>
		</Col>
	);
};

const MenuRender = () => {
	return (
		<Fragment>
			<PageTitle title={local.TS_SiteSettings} />
			{Notification("success", "Hello World", "Content here")}

			{isFeatureEnabled(features.SiteEdit) && CurrentUserHasRole(roles.SiteAdmin) && (
				<Row className="justify-content-center">
					<MenuWizard title={`${local.TS_Add} ${local.TS_Parameters}`} route="/site_settings/parameter_wizard" />
					<MenuWizard title={local.TF_Add_a_Logger} route="/site_settings/logger_wizard" />
				</Row>
			)}

			{isFeatureEnabled(features.SettingsWizards) && (
				<Row className="justify-content-center">
					<MenuWizard title={local.TF_Add_a_Logger} />
					<MenuWizard title={local.TF_Add_a_Transmitter} />
					<MenuWizard title={local.TF_Add_an_Alarm} />
				</Row>
			)}

			<CardDeck>
				{isFeatureEnabled(features.SiteLayout) && <MenuBlock icon="sitemap" title={local.TF_Site_Layout} description={local.TF_Site_Layout_description} route="/site_settings/site_layout" />}
				<MenuBlock icon="sliders-h" title={local.TF_Parameter_Setup} description={local.TF_Parameter_Setup_description} route="/site_settings/parameter_setup" />
				{isFeatureEnabled(features.ConfigureAlarms) && CurrentUserHasRole(roles.DepartmentAdmin) && <MenuBlock icon="bell" title={local.TF_Alarm_Setup} description={local.TF_Alarm_Setup_description} route="/site_settings/alarm_setup" />}

				{isFeatureEnabled(features.ZoneGraphicsSetup) && CurrentUserHasRole(roles.DepartmentAdmin) && <MenuBlock icon="map-marked-alt" title={local.TF_Zone_Graphic_Setup} description={local.TF_Zone_Graphic_Setup_description} route="/zone_graphics/setup" />}
				{isFeatureEnabled(features.DatabaseSettings) && CurrentUserHasRole(roles.SiteAdmin) && <MenuBlock icon="database" title={local.TF_Database_Settings} description={local.TF_Database_Settings_description} route="#!" />}
				{isFeatureEnabled(features.GeneralSettings) && CurrentUserHasRole(roles.SiteAdmin) && <MenuBlock icon="cog" title={local.TF_General_Settings} description={local.TF_Site_Language} route="/site_settings/general_settings" />}
				{isFeatureEnabled(features.LoggerConfiguration) && CurrentUserHasRole(roles.SiteAdmin) && <MenuBlock icon="router" title={local.TF_Logger_Configuration} description={local.TF_Logger_Configuration_Description} route="/site_settings/loggers" />}
			</CardDeck>

			{/* Change justify setting (e.g. to centre) when more options enabled */}
			<Row className="justify-content-left">
				{isFeatureEnabled(features.SiteManagement) && CurrentUserHasRole(roles.SiteAdmin) && <MenuMinor title={local.TF_Site_Management} route="/site_settings/site_management" />}
				{isFeatureEnabled(features.CalibrationSchedule) && <MenuMinor title={local.TF_Calibration_Schedule} />}
				{isFeatureEnabled(features.ImportOptions) && CurrentUserHasRole(roles.SiteAdmin) && <MenuMinor title={local.TF_Import_Options} />}
			</Row>
		</Fragment>
	);
};

export default MenuRender;
