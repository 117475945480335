import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { apiOutstandingLoggerChannelConfiguration, apiOutstandingLoggerSMSNumbersConfiguration, apiSQIRequest, apiSQIRequestOutstanding, apiSQIUploadOutstanding } from "../../../api/apiLoggerConfiguration";
import { apiSquirrelChannelsGetList, apiSquirrelRangesGetList, apiSquirrelsGetList } from "../../../api/apiSquirrels";
import { apiSiteSettingsList } from "../../../api/site";
import { features, isFeatureEnabled } from "../../../config";
import { logEvent } from "../../../helpers/ga";
import { tableIcons } from "../../../helpers/tableIcons";
import { getSquirrelRangeType, precisionRound } from "../../../helpers/utils";
import useWindowSize from "../../../hooks/useWindowSize";
import local from "../../../localization/strings";
import ButtonIcon from "../../common/ButtonIcon";
import PageTitle from "../../common/PageTitle";

const Logger = ({ match, history }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [groups, setGroups] = useState(false);
	const [noConnection, setNoConnection] = useState(false);
	const [gprsConnection, setGprsConnection] = useState(false);
	const [pendingSMS, setPendingSMS] = useState(false);
	const [ranges, setRanges] = useState([]);
	const [squirrel, setSquirrel] = useState({});
	const [outstanding, setOutstanding] = useState({});
	const [sqiRequestOutstanding, setSqiRequestOutstanding] = useState(true);
	const [sqiUploadOutstanding, setSqiUploadOutstanding] = useState(true);
	const size = useWindowSize();

	useEffect(() => {
		var dashboardContentDiv = document.getElementById("dashboard-container-div");

		if (dashboardContentDiv) {
			var contentDiv = document.getElementById("logger-div");
			contentDiv.style.marginRight = `-${dashboardContentDiv.offsetLeft}px`;
		}
	}, [size]);

	const channelIsOff = (rowData) => {
		if (!(rowData.log === null) && !rowData.log) {
			return true;
		}
		return false;
	};

	const calcStart = useCallback(
		(rangeNumber, set, start) => {
			if (set) {
				const range = ranges.find((x) => x.rangeNumber === rangeNumber);
				if (range) {
					let working = (start * range.span) / range.maxValue + range.minValue;
					return precisionRound(working * Math.pow(10, -range.decimalPlaces), 10).toString();
				}
				return "";
			}
			return "";
		},
		[ranges],
	);

	const HighStartDisplay = useCallback(
		({ rowData }) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const current = calcStart(rowData.range, rowData.highSet, rowData.highStart);

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				if (obj.HighSet !== rowData.highSet || obj.HighStart !== rowData.highStart) {
					const pending = calcStart(rowData.range, obj.HighSet, obj.HighStart);
					return (
						<>
							<div className="text-danger">{pending || "---"}</div>
							<div>{current}</div>
						</>
					);
				}
			}

			return <div>{current}</div>;
		},
		[calcStart, outstanding],
	);

	const LowStartDisplay = useCallback(
		({ rowData }) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const current = calcStart(rowData.range, rowData.lowSet, rowData.lowStart);

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				if (obj.LowSet !== rowData.lowSet || obj.LowStart !== rowData.lowStart) {
					const pending = calcStart(rowData.range, obj.LowSet, obj.LowStart);
					return (
						<>
							<div className="text-danger">{pending || "---"}</div>
							<div>{current}</div>
						</>
					);
				}
			}

			return <div>{current}</div>;
		},
		[calcStart, outstanding],
	);

	const LogDisplay = useCallback(
		({ rowData }) => {
			const currentValue = rowData.log === null ? true : rowData.log;
			let outstandingValue = currentValue;

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				outstandingValue = obj.Log === null ? true : obj.Log;
			}

			if (currentValue === outstandingValue) {
				return currentValue ? null : <div className="text-danger">{local.TF_OFF}</div>;
			} else {
				if (outstandingValue) {
					return <div className="text-danger">{local.TF_Turning_On}</div>;
				} else {
					return <div className="text-danger">{local.TF_Turning_Off}</div>;
				}
			}
		},
		[outstanding],
	);

	const RangeTypeDisplay = useCallback(
		({ rowData }) => {
			let display = "";
			const range = ranges.find((x) => x.rangeNumber === rowData.range);
			if (range) {
				display = getSquirrelRangeType(range.type);
			}

			return (
				<>
					<span>{display}</span> <LogDisplay rowData={rowData} />
				</>
			);
		},
		[ranges],
	);

	const rangeUnits = useCallback(
		(rowData) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const range = ranges.find((x) => x.rangeNumber === rowData.range);
			if (range) {
				return range.units;
			}
			return "";
		},
		[ranges],
	);

	const rangeCalcMin = useCallback((minValue, decimalPlaces) => {
		return minValue * Math.pow(10, -decimalPlaces);
	}, []);

	const rangeCalcMax = useCallback(
		(minValue, decimalPlaces, span) => {
			return span * Math.pow(10, -decimalPlaces) + rangeCalcMin(minValue, decimalPlaces);
		},
		[rangeCalcMin],
	);

	const rangeMinToMax = useCallback(
		(rowData) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const range = ranges.find((x) => x.rangeNumber === rowData.range);
			if (range) {
				return `${rangeCalcMin(range.minValue, range.decimalPlaces)} ${local.TS_To} ${rangeCalcMax(range.minValue, range.decimalPlaces, range.span)}`;
			}
			return "";
		},
		[ranges, rangeCalcMin, rangeCalcMax],
	);

	const calcAlarmDelay = useCallback(
		(alarmDelay) => {
			if (!alarmDelay) {
				return "";
			}

			const interval = squirrel.scanInterval || squirrel.logInterval;
			var dt = new Date("1970-01-01T" + interval + "Z");
			const seconds = dt.getUTCSeconds() + dt.getUTCMinutes() * 60 + dt.getUTCHours() * 60 * 60;
			const delaySeconds = seconds * alarmDelay;

			return `${alarmDelay} (${new Date(delaySeconds * 1000).toISOString().substring(11, 19)})`;
		},
		[squirrel],
	);

	const AlarmDelayDisplay = useCallback(
		({ rowData }) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const current = calcAlarmDelay(rowData.alarmDelay);

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				if (obj.AlarmDelay !== rowData.alarmDelay) {
					const pending = calcAlarmDelay(obj.AlarmDelay);
					return (
						<>
							<div className="text-danger">{pending || "---"}</div>
							<div>{current}</div>
						</>
					);
				}
			}

			return <div>{current}</div>;
		},
		[calcAlarmDelay, outstanding],
	);

	const GroupDisplay = useCallback(
		({ rowData }) => {
			if (channelIsOff(rowData)) {
				return "";
			}
			const current = rowData.smsGroup;

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				if (obj.SMSGroup !== rowData.smsGroup) {
					const pending = obj.SMSGroup;
					return (
						<>
							<div className="text-danger">{pending || "---"}</div>
							<div>{current}</div>
						</>
					);
				}
			}

			return <div>{current}</div>;
		},
		[outstanding],
	);

	const IdentityDisplay = useCallback(
		({ rowData }) => {
			const current = rowData.identity;

			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				const jsonString = outstanding[rowData.channelNumber.toString()];
				const obj = JSON.parse(jsonString);

				if (obj.Name !== rowData.identity) {
					const pending = obj.Name;
					return (
						<>
							<div className="text-danger">{pending || "---"}</div>
							<div>{current}</div>
						</>
					);
				}
			}

			return <div>{current}</div>;
		},
		[outstanding],
	);

	const TransmitterSerialNumberDisplay = useCallback(({ rowData }) => {
		if (channelIsOff(rowData)) {
			return "";
		}
		return <div>{rowData.transmitterSerialNumber && rowData.transmitterSerialNumber !== "0" ? rowData.transmitterSerialNumber : ""}</div>;
	}, []);

	const TransmitterChannelNumberDisplay = useCallback(({ rowData }) => {
		if (channelIsOff(rowData)) {
			return "";
		}
		return <div>{rowData.transmitterSerialNumber && rowData.transmitterSerialNumber !== "0" ? String.fromCharCode(65 + rowData.transmitterChannelNumber) : ""}</div>;
	}, []);

	const TransmitterIntervalDisplay = useCallback(({ rowData }) => {
		if (channelIsOff(rowData)) {
			return "";
		}
		return <div>{rowData.transmitterInterval ? new Date(rowData.transmitterInterval * 1000).toISOString().substring(11, 19) : ""}</div>;
	}, []);

	const columns = [
		{
			field: "channelNumber",
			title: local.TF_Channel_Number,
			render: (rowData) => (
				<div>
					{rowData.channelNumber + 1}
					{Object.keys(outstanding).includes(rowData.channelNumber.toString()) ? <FontAwesomeIcon icon="hourglass-half" color="#932338" title={local.TF_Change_Pending} className="ml-1" /> : ""}
				</div>
			),
			defaultSort: "asc",
		}, //
		{ field: "identity", title: local.TS_Name, render: (rowData) => <IdentityDisplay rowData={rowData} /> },
		{ field: "DF_1", title: local.TF_Input, render: (rowData) => <RangeTypeDisplay rowData={rowData} /> },
		{ field: "DF_2", title: local.TS_Range, render: (rowData) => rangeMinToMax(rowData) },
		{ field: "DF_3", title: local.TS_Units, render: (rowData) => rangeUnits(rowData) },
		{ field: "highStart", title: local.TF_High_Start, render: (rowData) => <HighStartDisplay rowData={rowData} /> },
		{ field: "lowStart", title: local.TF_Low_Start, render: (rowData) => <LowStartDisplay rowData={rowData} /> },
		{ field: "alarmDelay", title: local.TF_Delay, render: (rowData) => <AlarmDelayDisplay rowData={rowData} /> },
		{ field: "smsGroup", title: local.TF_Group_Name, hidden: !groups, render: (rowData) => <GroupDisplay rowData={rowData} /> },
		{ field: "transmitterSerialNumber", title: local.TF_TransmitterSerialNumber, render: (rowData) => <TransmitterSerialNumberDisplay rowData={rowData} /> },
		{ field: "transmitterChannelNumber", title: local.TF_Transmitter_Channel, render: (rowData) => <TransmitterChannelNumberDisplay rowData={rowData} /> },
		{ field: "transmitterInterval", title: local.TF_Transmit_Interval, render: (rowData) => <TransmitterIntervalDisplay rowData={rowData} /> },
	];

	function getBit(number, bitPosition) {
		return (number & (1 << bitPosition)) === 0 ? 0 : 1;
	}

	const loadData = useCallback(async () => {
		const sn = match?.params?.serialNumber;

		setOutstanding((await apiOutstandingLoggerChannelConfiguration(sn)).data);
		setSqiRequestOutstanding((await apiSQIRequestOutstanding(sn)).data);
		setSqiUploadOutstanding((await apiSQIUploadOutstanding(sn)).data);

		const pendingSMSConfig = await apiOutstandingLoggerSMSNumbersConfiguration(sn);
		if (pendingSMSConfig?.data) {
			setPendingSMS(true);
		} else {
			setPendingSMS(false);
		}

		const siteSettingsResult = await apiSiteSettingsList();
		if (siteSettingsResult.find((x) => x.settingKey === `${sn}/NoConnection` && x.settingValue === "0")) {
			setNoConnection(false);
		} else {
			setNoConnection(true);
		}
		if (siteSettingsResult.find((x) => x.settingKey === `${sn}/GPRSConnection` && x.settingValue === "1")) {
			setGprsConnection(true);
		} else {
			setGprsConnection(false);
		}
		const squirrels = await apiSquirrelsGetList();
		const foundSquirrel = squirrels.find((x) => x.serialNumber === sn);
		setGroups(getBit(foundSquirrel.specialDarca, 1) === 1);
		setSquirrel(foundSquirrel);
		const apiResultRanges = await apiSquirrelRangesGetList();
		setRanges(apiResultRanges.filter((x) => x.serialNumber === sn));
		const apiResult = await apiSquirrelChannelsGetList();
		setData(apiResult.filter((x) => x.serialNumber === sn));

		setLoading(false);
	}, [match]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	const editLoggerChannel = useCallback(
		(_, rowData) => {
			if (Object.keys(outstanding).includes(rowData.channelNumber.toString())) {
				return;
			}

			if (noConnection) {
				return;
			}

			if (rowData.transmitterSerialNumber === "0" || rowData.range === 0) {
				return;
			}

			history.push(`/site_settings/logger/${rowData.serialNumber}/${rowData.channelNumber}`);
		},
		[history, outstanding, noConnection],
	);

	const onSqiRequest = useCallback(async () => {
		setSqiRequestOutstanding(true);
		await apiSQIRequest(match?.params?.serialNumber);
		loadData();
	}, [loadData, match]);

	return (
		<div id="logger-div">
			<Row>
				<Col sm={10}>
					<PageTitle title={`${local.TF_Logger_Configuration}: ${match?.params?.serialNumber || ""}`} />
				</Col>

				<Col>
					<ButtonIcon
						outline
						className="float-right mt-3 dark-button mx-1"
						size="sm"
						icon="arrow-left"
						iconAlign="left"
						color="dark"
						onClick={() => {
							logEvent("Configure Logger", "Back Button");
							history.push(`/site_settings/loggers`);
						}}
					>
						{local.TS_Back}
					</ButtonIcon>
				</Col>
			</Row>
			{!loading && (
				<Row>
					<Col>
						{gprsConnection && isFeatureEnabled(features.LoggerSQIConfiguration) && (
							<>
								<ButtonIcon
									outline
									className="my-2 dark-button mx-1"
									size="sm"
									icon={sqiRequestOutstanding ? "hourglass-half" : "download"}
									iconAlign="left"
									disabled={sqiRequestOutstanding}
									color="dark"
									onClick={() => {
										logEvent("Configure Logger", "Get SQI File");
										onSqiRequest();
									}}
								>
									{local.TF_Get_SQI_File}
								</ButtonIcon>
								<ButtonIcon
									outline
									className="my-2 dark-button mx-1"
									size="sm"
									icon={sqiUploadOutstanding ? "hourglass-half" : "upload"}
									iconAlign="left"
									disabled={sqiUploadOutstanding}
									color="dark"
									onClick={() => {
										logEvent("Configure Logger", "Upload SQI File");
										history.push(`/site_settings/upload-sqi/${match?.params?.serialNumber}`);
									}}
								>
									{local.TF_Upload_SQI_File}
								</ButtonIcon>
							</>
						)}
						{squirrel?.sms && (
							<>
								<ButtonIcon
									outline
									className="my-2 dark-button mx-1"
									size="sm"
									icon="bell"
									iconAlign="left"
									color="dark"
									onClick={() => {
										logEvent("Configure Logger", "SMS Numbers Button");
										history.push(`/site_settings/logger-sms-numbers/${match?.params?.serialNumber}`);
									}}
								>
									{local.TF_SMS_Numbers}
								</ButtonIcon>
								{pendingSMS && <FontAwesomeIcon icon="hourglass-half" color="#932338" title={local.TF_Change_Pending} className="ml-1" />}
							</>
						)}
					</Col>
				</Row>
			)}
			{noConnection && <div className="text-danger mb-2">{local.TF_Squirrel_NoConnection}</div>}
			<MaterialTable columns={columns} onRowClick={editLoggerChannel} data={data} title="" icons={tableIcons} options={{ sorting: true, paging: true, pageSize: 20, pageSizeOptions: [20, 50, 100], emptyRowsWhenPaging: false, showEmptyDataSourceMessage: false, headerStyle: { backgroundColor: "#edf2f9", color: "#01579b" } }} />
		</div>
	);
};

export default withRouter(Logger);
